/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import {
  Row, Col, Input, Label, Form, FormFeedback,
} from "reactstrap";

var videoSlotList = [];

const PlayerAdConfig = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [adConfig, setAdConfig] = useState({
    adsState: '',
    breakingAds: 15,
    maxAdsPerSlot: 0,
    adBreak: 0,
    midRollSlots: [...videoSlotList],
    preRollSlots: {},
    vastRerun: 0,
    faultCheck: 0,
    vastTag: '',
    vast_mute: '',
    preRollState: false,
    preRollVastTag: '',
    midRollVastTag: ''
  })

  const [preroll, setPreroll] = useState({
    firstAdTimeout: 15,
    adTimeout: 5,
    startAdAt: 0,
    maxAdsPerSlots: 1,
    nextAdTimeout: 8,
  })

  console.log(adConfig)

  const handleChange = e => {
    let { name, value } = e.target;
    console.log(name, value)
    setAdConfig(adConfig => ({ ...adConfig, [name]: value }));
    props.selectedItems({ ...adConfig, [name]: value })
    if (name === 'adsState') {
      videoSlotList = []
      videoSlotList.push({
        firstAdTimeout: 15,
        adTimeout: 5,
        startAdAt: 0,
        maxAdsPerSlots: 1,
        nextAdTimeout: 8,
      })
      setSlotList([...videoSlotList])
      setAdConfig(adConfig => ({
        ...adConfig, breakingAds: 15,
        maxAdsPerSlot: 0,
        adBreak: 0,
        midRollSlots: [...videoSlotList],
        preRollSlots: {},
        vastRerun: 0,
        faultCheck: 0,
        vastTag: '',
        vast_mute: '',
        preRollState: false,
        preRollVastTag: '',
        midRollVastTag: ''
      }));
      props.selectedItems({
        ...adConfig, breakingAds: 15,
        maxAdsPerSlot: 0,
        adBreak: 0,
        midRollSlots: [...videoSlotList],
        preRollSlots: {},
        vastRerun: 0,
        faultCheck: 0,
        vastTag: '',
        vast_mute: '',
        preRollState: false,
        preRollVastTag: '',
        midRollVastTag: ''
      })
    }
  }

  const handleChangeNumber = e => {
    let { name, value } = e.target;
    setAdConfig(adConfig => ({ ...adConfig, [name]: Number(value) }));
    props.selectedItems({ ...adConfig, [name]: Number(value) })
  }

  const [slotList, setSlotList] = useState([...videoSlotList]);
  const [addNewSlot, setAddNewSlot] = useState(true);
  const addNewVideoSlot = () => {
    setAddNewSlot(false)
    console.log(adConfig)
    if (videoSlotList.length > 0) {
      videoModal(parseInt(videoSlotList[videoSlotList.length - 1].startAdAt) + 5)
    } else {
      videoModal(2)
    }
  }

  useEffect(async () => {
    console.log(props.selectedPlayer)
    if (props.selectedPlayer !== '') {
      setAdConfig({ ...adConfig, ...props.selectedPlayer.playerSettingsConfig })
      setPreroll({ ...preroll, ...props.selectedPlayer.playerSettingsConfig.preRollSlots });
      videoSlotList = props.selectedPlayer.playerSettingsConfig.midRollSlots
      setSlotList([...videoSlotList])
      props.selectedItems({ ...adConfig, preRollSlots: props.selectedPlayer.playerSettingsConfig.preRollSlots, midRollSlots: props.selectedPlayer.playerSettingsConfig.midRollSlots })
    } else {
      if (videoSlotList.length < 1) {
        addNewVideoSlot();
      }
    }
  }, [])

  const videoModal = (value) => {
    videoSlotList.push({
      firstAdTimeout: 15,
      adTimeout: 5,
      startAdAt: value,
      maxAdsPerSlots: 1,
      nextAdTimeout: 8,
    })
    setSlotList([...videoSlotList])
    setAdConfig(adConfig => ({ ...adConfig, midRollSlots: [...videoSlotList] }));
    console.log(videoSlotList)
  }

  const slotHandleChange = (index) => e => {
    const { name, value } = e.target;
    videoSlotList[index] = { ...videoSlotList[index], [name]: Number(value) };
    setSlotList([...videoSlotList])
    setAdConfig(adConfig => ({ ...adConfig, midRollSlots: [...videoSlotList] }));
    props.selectedItems({ ...adConfig, midRollSlots: [...videoSlotList] })
  }

  const handleCheck = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setAdConfig(adConfig => ({ ...adConfig, preRollState: e.target.checked, preRollSlots: preroll,preRollVastTag:'' }));
      props.selectedItems({ ...adConfig, preRollState: e.target.checked, preRollSlots: preroll,preRollVastTag:'' })
    } else {
      setAdConfig(adConfig => ({ ...adConfig, preRollState: e.target.checked, preRollSlots: {},preRollVastTag:'' }));
      props.selectedItems({ ...adConfig, preRollState: e.target.checked, preRollSlots: {},preRollVastTag:'' })
    }
  }

  const prerollHandleChange = (e) => {
    const { name, value } = e.target;
    let prerollData = { ...preroll, [name]: Number(value) }
    setPreroll(preroll => ({ ...preroll, [name]: Number(value) }));
    setAdConfig(adConfig => ({ ...adConfig, preRollSlots: prerollData }));
    props.selectedItems({ ...adConfig, preRollSlots: prerollData })
  }

  const deleteHandler = (i) => (e) => {
    if (videoSlotList.length > 0) {
      console.log(i)
      videoSlotList.splice(i, 1)
      console.log(videoSlotList)
      setSlotList([...videoSlotList])
      setAdConfig(adConfig => ({ ...adConfig, midRollSlots: [...videoSlotList] }));
      props.selectedItems({ ...adConfig, midRollSlots: [...videoSlotList] })
    }
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  const createUI = () => {
    return slotList.map((item, i) =>
      <div key={i}>
        <div>
          {true && <label
            className="form-check-label mt-2"
          >
            {`Midroll slot ${i + 1}`}
          </label>}
          <div className="row mt-1">

            <div className='col-2'>
              <span className=' mt-2'>First Ad Timeout</span>
            </div>
            <div className='col-2'>
              <span className='mt-2'> Ad Timeout</span>
            </div>
            <div className='col-2'>
              <span className='mt-2'>Start Ad At</span>
            </div>
            <div className='col-2'>
              <span className='mt-2'> Max Ads Per Slots</span>
            </div>
            <div className='col-2'>
              <span className='mt-2'>Next Ad Timeout</span>
            </div>
          </div>
          <div className="row ">
            <div className='col-2'>
              <Input
                type="text" name="firstAdTimeout" value={item.firstAdTimeout} onChange={slotHandleChange(i)} onKeyPress={allowOnlyNumbers}
              />
            </div>
            <div className='col-2'>
              <Input
                type="text" name="adTimeout" value={item.adTimeout} onChange={slotHandleChange(i)} onKeyPress={allowOnlyNumbers}
              />
            </div>
            <div className='col-2'>
              <Input
                type="text" name="startAdAt" value={item.startAdAt} onChange={slotHandleChange(i)} onKeyPress={allowOnlyNumbers}
              />
            </div>
            <div className='col-2'>
              <Input
                type="text" name="maxAdsPerSlots" value={item.maxAdsPerSlots} onChange={slotHandleChange(i)} onKeyPress={allowOnlyNumbers}
              />
            </div>
            <div className='col-2'>
              <Input
                type="text" name="nextAdTimeout" value={item.nextAdTimeout} onChange={slotHandleChange(i)} editable={true} onKeyPress={allowOnlyNumbers}
              />
            </div>
            <div className='col-2'>
              <button type='button' className='btn-danger mt-1' style={{ width: "4vh", cursor: 'pointer' }} onClick={deleteHandler(i)} disabled={videoSlotList.length === 1}>X</button>
            </div>

          </div>
        </div>
      </div>
    )
  }

  const createPrerollUI = () => {
    return (
      <div>
        <div className="row mt-3">
          <div className='col-2'>
            <span className=' mt-2'>First Ad Timeout</span>
          </div>
          <div className='col-2'>
            <span className='mt-2'> Ad Timeout</span>
          </div>
          <div className='col-2'>
            <span className='mt-2'>Start Ad At</span>
          </div>
          <div className='col-2'>
            <span className='mt-2'> Max Ads Per Slots</span>
          </div>
          <div className='col-2'>
            <span className='mt-2'>Next Ad Timeout</span>
          </div>
        </div>
        <div className="row ">
          <div className='col-2'>
            <Input
              type="number" name="firstAdTimeout" value={preroll.firstAdTimeout} onChange={prerollHandleChange} onKeyPress={allowOnlyNumbers}
            />
          </div>
          <div className='col-2'>
            <Input
              type="number" name="adTimeout" value={preroll.adTimeout} onChange={prerollHandleChange} onKeyPress={allowOnlyNumbers}
            />
          </div>
          <div className='col-2'>
            <Input
              type="number" name="startAdAt" disabled value={preroll.startAdAt} onChange={prerollHandleChange} editable={true} onKeyPress={allowOnlyNumbers}
            />
          </div>
          <div className='col-2'>
            <Input
              type="number" name="maxAdsPerSlots" value={preroll.maxAdsPerSlots} onChange={prerollHandleChange} onKeyPress={allowOnlyNumbers}
            />
          </div>
          <div className='col-2'>
            <Input
              type="number" name="nextAdTimeout" value={preroll.nextAdTimeout} onChange={slotHandleChange} editable={true} onKeyPress={allowOnlyNumbers}
            />
          </div>

        </div>
        <hr style={{ height: 5, color: "#000000" }} />
      </div>
    )
  }
  return (
    <div>
      <div className='player-content'>
        <div className="container-fluid">
          <h4 className='heading3'>Ad Setup</h4>
          <div className='row mb-4'>
            <div className='col-md-5'>
              <div className="form-group mt-3">
                <Label className="form-label"> Ads State</Label>
                <Input
                  type="select"
                  className="form-select"
                  value={adConfig.adsState}
                  onChange={handleChange}
                  label="AdsMode"
                  name="adsState"
                >
                  <option value="" disabled >
                    Select Ads State
                  </option>
                  <option value="breakingAds"  >
                    Ad Breaks
                  </option>
                  <option value="midRollSlots"  >
                    Slot
                  </option>
                </Input>
              </div>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-6'>
            </div>
            <div className='col-md-5'>
              <div className="form-group mt-3 mb-3">
                <Label className="form-label">Vast Rerun</Label>
                <Input
                  type="text"
                  value={adConfig.vastRerun}
                  onChange={handleChangeNumber}
                  onKeyPress={allowOnlyNumbers}
                  name="vastRerun"
                />
              </div>
              <div className="form-group mt-3">
                <Label className="form-label">Fault Check</Label>
                <Input
                  type="text"
                  value={adConfig.faultCheck}
                  onChange={handleChangeNumber}
                  onKeyPress={allowOnlyNumbers}
                  name="faultCheck"
                />
              </div>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-6'>
            </div>
          </div>


          {adConfig.adsState === 'breakingAds' &&
            <div className='col-5'>
              <div className="mb-3">
                <Label className="form-label">Vast Tag</Label>
                <Input
                  name="vastTag"
                  type="textarea" id="textarea"
                  rows="3"
                  style={{ fieldSizing: 'content', width: '100%', minHeight: '4pc' }}
                  onChange={handleChange}
                  value={adConfig.vastTag || ""}
                  className={'form-control'}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Vast Tag (Muted)</Label>
                <Input
                  name="vast_mute"
                  type="textarea" id="textarea"
                  rows="3"
                  style={{ fieldSizing: 'content', width: '100%', minHeight: '4pc' }}
                  onChange={handleChange}
                  value={adConfig.vast_mute || ""}
                  className={'form-control'}
                />
              </div>
              <div className="form-group  mt-3">
                <Label className="form-label">Total Ads per Player</Label>
                <Input
                  type="text" name="breakingAds" value={adConfig.breakingAds} onChange={handleChangeNumber} onKeyPress={allowOnlyNumbers}
                />
              </div>
              <div className="form-group  mt-3">
                <Label className="form-label">Maximum Ads per Slot</Label>
                <Input
                  type="text" name="maxAdsPerSlot" value={adConfig.maxAdsPerSlot} onChange={handleChangeNumber} onKeyPress={allowOnlyNumbers}
                />
              </div>
              <div className="form-group  mt-3">
                <Label className="form-label">Ad Break Time</Label>
                <Input
                  type="text" name="adBreak" value={adConfig.adBreak} onChange={handleChangeNumber} onKeyPress={allowOnlyNumbers}
                />
              </div>
            </div>
          }
          {adConfig.adsState === 'midRollSlots' &&
            <div className='col-12'>
              <h5 className=' mt-4'>Ad Slots</h5>
              <div className="form-group ">
                <div className="form-check form-switch form-check-success form-switch-md mt-2 mb-3" >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="PrerollSlot"
                    checked={adConfig.preRollState} onChange={handleCheck} name="PrerollSlot"
                  />
                  <label className="form-check-label " htmlFor="PrerollSlot">
                    <h5 className="heading5 mt-1">Preroll Slot</h5>
                  </label>
                </div>
                {/* <div className="form-check mt-2 mb-2">
                  <label
                    className="form-check-label"
                  >
                    Preroll Slot
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={adConfig.preRollState}
                    onChange={handleCheck}
                  />

                </div> */}
              </div>

              {adConfig.preRollState && <div>
                <h5 className=' mt-4'>Preroll</h5>
                <div className="mb-3 col-6">
                  <Label className="form-label">Preroll Vast Tag</Label>
                  <Input
                    name="preRollVastTag"
                    type="textarea" id="textarea"
                    rows="3"
                    style={{ fieldSizing: 'content', minHeight: '4pc' }}
                    onChange={handleChange}
                    value={adConfig.preRollVastTag || ""}
                    className={'form-control col-5'}
                  />
                </div>
                {createPrerollUI()}
              </div>
              }
              {<div>
                <div className="d-flex ">
                  <h5 className=' mt-1'>Midroll</h5>
                  <button type='button' className='btn btn-primary ' onClick={addNewVideoSlot} style={{ marginLeft: 'auto' }}>Add Midroll Slots</button>
                </div>
                <div className="mb-3 col-6">
                  <Label className="form-label">Midroll Vast Tag</Label>
                  <Input
                    name="midRollVastTag"
                    type="textarea" id="textarea"
                    rows="3"
                    style={{ fieldSizing: 'content', minHeight: '4pc' }}
                    onChange={handleChange}
                    value={adConfig.midRollVastTag || ""}
                    className={'form-control col-5'}
                  />
                </div>
                {createUI()}</div>}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PlayerAdConfig;
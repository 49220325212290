/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Row, Col, Input, Label, Form, FormFeedback,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container, InputGroup, Modal, ModalBody
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import classnames from "classnames"

const PlayerControls = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [controls, setControls] = useState({
    closeBtn_position: "outer-right",
    closeBtn_bottomMargin: 0,
    closeBtn_rightMargin: 0,
    closeBtn_topMargin: 0,
    closeBtn_leftMargin: 0,
    closeBtn_height: 18,
    closeBtn_width: 18,
    closeBtn_Text: '',
    closeBtn_InfoURL: 'https://bidsxchange.com/',
    showPlaylist: false,
    previousNextButton: true,
    backwordForwordButton: true,
    playPauseButton: true,
    playOnViewPercentage: 50,
    fullscreenToggle: true,
    duration: true,
    soundButton: true,
    qualitySelector: true,
    playbackRates: true,
    autoplay: true,
    autoContinue: true,
    loopPlaylistContent: true,
    continueRequestAd: true,
    continuePlayerOnTab: true,
    brandIdentity: false,

    autoSkip: false,
    loadAdOnView: true,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    muted: false,
    isResponsive: true,
    playerSize: 'desktop',
    playerHeight: 360,
    playerWidth: 640,
    floatingCloseButton: true,
    floatOnStart: true,
    floatingOnlyOnAd: true,
    floatingMarginLeft: 10,
    floatingMarginRight: 10,
    floatingMarginTop: 10,
    floatingMarginBottom: 10,
    floatingPlacement: "bottom-right",
    floatingZindex: "99999",
    floatingSize: 'vhandvw',
    floatingSizeHeight: '',
    floatingSizeWidth: '',
    floatingSizeVH: 50,
    floatingSizeVW: 50,
    floatOnView: 50,
    floatOnViewPlayer: false,
    floatOnlyBottom: false,
    playerState: 'inRead',
    stickyPosition: "bottom",
    stickyPlacement: 'right',
    contentIdentifierState: false,
    contentIdentifier: 'fixed',
    contentPosition: 'midPage',
    contentSelector: 'tag',
    contentIdentifierName: '',
    fallBackPositionIdentifier: '',
    customCss: '',
    backgroundColor: '',
    closeBtn: true,
    logo: true,
    logoPosition: 'top-right',
    logoHeight: 21,
    logoWidth: 21,
    logoURL: "",
    // logoURL: "https://admin.bidsxchange.com/images/shards-dashboard.comm",
    logoLpu: '',
    logoClickThroughURL: "https://streamlyn.com/products-seller",
    logoToolTip: '',
    logoTextFontSize: 14,
    logoText: "Ads by Bidsxchange",



    playAdInView: true,
    skipButton: true,
    skipTimer: 30,
    skipText: 'skip',
    timeToSkip: 25,
    // showAutoSkipButtons: '',
    autoSkipText: 'stay',
    dfp1x1: false,
    contentIdentifierNo: 0,
    user_consent: true,
    showSocialShare: true,
    views: true,
    like: true,
    share: true,

    playlistPosition: 'bottom',

    desktopSettingsEnabled: true,
    mobileSettingsEnabled: false,
    mobileSettings: {
      playerSize: 'mobile',
      playerHeight: 225,
      playerWidth: 400,
      floatingCloseButton: true,
      floatOnStart: true,
      floatingOnlyOnAd: true,
      floatingMarginLeft: 10,
      floatingMarginRight: 10,
      floatingMarginTop: 10,
      floatingMarginBottom: 10,
      floatingPlacement: "bottom-right",
      floatingZindex: "99999",
      floatingSize: 'vhandvw',
      floatingSizeHeight: '',
      floatingSizeWidth: '',
      floatingSizeVH: 50,
      floatingSizeVW: 50,
      floatOnView: 50,
      floatOnViewPlayer: false,
      floatOnlyBottom: false,
      playerState: 'inRead',
      contentIdentifierState: false,
      contentIdentifier: 'custom',
      contentPosition: 'midPage',
      contentSelector: 'tag',
      contentIdentifierName: '',
      contentIdentifierNo: 0,
    },

    playOnView: true,
    loadOnAdBlock: false,
    playerLoadOnPageView: true,
    startOnView: 10,
    requestAdOnView: 10,
    playAdOnView: 10,
    continueRequestAd: true,
    continuePlayerOnTab: true,
    checkTabInView: true,

    clickToPlay: false,
    clickToPlayRequestAd: false,
    clickToPlayRequestAdTime: 0,
    clickToPlayRequestAdViewport: '',
    volumePlayer: 20,
    volumePageInteraction: 0.2,
    user_consent_title: 'Video will play with sound.',
    user_consent_heading_text: 'Do you wish to continue?',
    user_consent_button_text: 'Yes, Continue!',
    user_consent_button_color: '#cfff04',
    user_consent_cancel_text: 'No Thanks',
    user_consent_close_button_timer: 5,
    user_consent_viewOnPercentage: 50,
    user_consent_close_position: 'top-left',
    unMuteOnEvents: true,
    unMuteEvents: ['on_scroll', 'on_keyboard_click', 'on_page_cilck'],
    autoContinueNextContent: true,
    loopPlaylistContent: true,
    pauseOnUnseen: false,
    advertisingLabel: false,
    advertisingLabelText: '',
    playerSizeMgmt: 'fixed',
  });
  const [activeTab, setactiveTab] = useState(1)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
      }
    }
  }
  const [showAlert, setShowAlert] = useState(false);

  const [selectedEvents, setSelectedEvents] = useState([]);

  useEffect(async () => {
    console.log(props.selectedPlayer)
    if (props.selectedPlayer !== '') {
      setControls({ ...controls, ...props.selectedPlayer.playerSettingsAesthetics })
      if (props.selectedPlayer.playerSettingsAesthetics.unMuteEvents && props.selectedPlayer.playerSettingsAesthetics.unMuteEvents.length > 0) {
        setSelectedEvents(objectModal(props.selectedPlayer.playerSettingsAesthetics.unMuteEvents))
      }
    } else {
      setSelectedEvents(objectModal(['on_scroll', 'on_keyboard_click', 'on_page_cilck']))
    }
  }, [])

  useEffect(async () => {
    if (props.settingsData.PlayerSettingType !== '') {
      // if (props.settingsData.PlayerSettingType === 'all') {
      //   setControls(controls => ({ ...controls, mobileSettingsEnabled: true, desktopSettingsEnabled: true }));
      //   props.selectedItems({ ...controls, mobileSettingsEnabled: true, desktopSettingsEnabled: true })
      // } else if (props.settingsData.PlayerSettingType === 'mobile') {
      //   setControls(controls => ({ ...controls, mobileSettingsEnabled: true, desktopSettingsEnabled: false }));
      //   props.selectedItems({ ...controls, mobileSettingsEnabled: true, desktopSettingsEnabled: false })
      // } else {
      //   setControls(controls => ({ ...controls, desktopSettingsEnabled: true, mobileSettingsEnabled: false }));
      //   props.selectedItems({ ...controls, desktopSettingsEnabled: true, mobileSettingsEnabled: false })
      // }

      setControls(controls => ({ ...controls, mobileSettingsEnabled: props.settingsData.playerSettingsAesthetics.mobileSettingsEnabled, desktopSettingsEnabled: props.settingsData.playerSettingsAesthetics.desktopSettingsEnabled }));
    }
  }, [props.settingsData.PlayerSettingType])

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  //old one
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setControls((controls) => ({ ...controls, [name]: value }));
  //   props.selectedItems({ ...controls, [name]: value })

  // };

  const handleSelectEvent = events => {
    var eventList = []
    setSelectedEvents(events);
    events.map((item, index) => {
      eventList.push(item.value)
    })
    setControls(controls => ({ ...controls, unMuteEvents: eventList }));
    props.selectedItems({ ...controls, unMuteEvents: eventList })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValues = { ...controls, [name]: value };

    if (value === 'desktop') {
      updatedValues = { ...updatedValues, playerWidth: 640, playerHeight: 360 };
    } else if (value === 'mobile') {
      updatedValues = { ...updatedValues, playerWidth: 400, playerHeight: 225 };
    }

    setControls(updatedValues);
    props.selectedItems(updatedValues);
  };

  const handleChangeNumbers = (e) => {
    const { name, value } = e.target;
    let updatedValues = { ...controls, [name]: Number(value) };
    setControls(updatedValues);
    props.selectedItems(updatedValues);
  };

  //old one
  // const handleCheck = (event) => {
  //   console.log(event.target.name)
  //   setControls({
  //     ...controls,
  //     [event.target.name]: event.target.checked,
  //   });
  //   props.selectedItems({ ...controls, [event.target.name]: event.target.checked })
  // };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    // if (name === "autoplay" && checked) {
    //   setShowAlert(!showAlert)
    // } else {
    const updatedControls = {
      ...controls,
      [name]: checked,
    };

    // if (name === "playOnView" && checked) {
    //   updatedControls.autoplay = false;
    // } 
    // if (name === 'autoSkip' && checked) {
    //   updatedControls.skipButton = false;
    // } else if (name === 'skipButton' && checked) {
    //   updatedControls.autoSkip = false;
    // } else if (name === "user_consent" && checked) {
    //   updatedControls.muted = true;
    //   updatedControls.volumePlayer = 0
    // }
    // else if (name === "muted" && checked) {
    //   updatedControls.user_consent = false;
    // }
    // else if (name === "muted" && !checked) {
    //   updatedControls.user_consent = true;
    // }
    // else if (name === "clickToPlay" && checked) {
    //   updatedControls.autoplay = false;
    //   updatedControls.playOnView = false;
    //   updatedControls.clickToPlayRequestAd = true;
    // }
    // else if (name === "clickToPlay" && !checked) {
    //   updatedControls.autoplay = true;
    //   updatedControls.clickToPlayRequestAd = false;
    // }
    // else if (name === "autoplay" && !checked) {
    //   updatedControls.clickToPlay = true;
    //   updatedControls.clickToPlayRequestAd = true;
    // } else if (name === 'floatOnViewPlayer' && checked) {
    //   updatedControls.floatingOnlyOnAd = false
    // } else if (name === 'floatingOnlyOnAd' && checked) {
    //   updatedControls.floatOnViewPlayer = false
    // } else if (name === 'unMuteOnEvents' && !checked) {
    //   setSelectedEvents([]);
    // }
    // if ((name === "playOnView" && checked) && controls.user_consent) {
    //   updatedControls.muted = false;
    //   updatedControls.volumePlayer = 0
    // }
    // if (name === "user_consent" && checked) {
    //   updatedControls.muted = false;
    //   updatedControls.volumePlayer = 0
    // }
    // if ((name === "playOnView" && checked) && !controls.user_consent) {
    //   updatedControls.muted = true;
    //   updatedControls.volumePlayer = 1
    // }

    if (name === 'floatOnViewPlayer' && checked) {
      updatedControls.floatingOnlyOnAd = false
    } else if (name === 'floatingOnlyOnAd' && checked) {
      updatedControls.floatOnViewPlayer = false
    }
    if (name === 'showPlaylist') {
      if (!checked) {
        updatedControls.playlistPosition = 'bottom';
      }
    }

    setControls(updatedControls);
    props.selectedItems(updatedControls);



  };

  const autoPlayCheck = async () => {
    const updatedControls = { ...controls };
    updatedControls.autoplay = true;
    // updatedControls.playOnView = false;
    // updatedControls.user_consent = false;
    updatedControls.clickToPlayRequestAd = false;
    updatedControls.clickToPlay = false;
    setControls(updatedControls);
    props.selectedItems(updatedControls);
    setShowAlert(false)
  }



  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };


  const handleChangeMobile = (e) => {
    const { name, value } = e.target;
    let updatedValues = { ...controls, mobileSettings: { ...controls.mobileSettings, [name]: value } };
    if (value === 'desktop') {
      updatedValues = { ...controls, mobileSettings: { ...controls.mobileSettings, playerWidth: 640, playerHeight: 360 } };
    } else if (value === 'mobile') {
      updatedValues = { ...controls, mobileSettings: { ...controls.mobileSettings, playerWidth: 400, playerHeight: 225 } };
    }
    setControls(updatedValues);
    props.selectedItems(updatedValues);
  };

  const handleChangeNumbersMobile = (e) => {
    const { name, value } = e.target;
    let updatedValues = { ...controls, mobileSettings: { ...controls.mobileSettings, [name]: Number(value) } };
    setControls(updatedValues);
    props.selectedItems(updatedValues);
  };

  const handleCheckMobile = (event) => {
    const { name, checked } = event.target;
    let updatedControls = {
      ...controls, mobileSettings: {
        ...controls.mobileSettings,
        [name]: checked,
      }
    };
    if (name === 'floatOnViewPlayer' && checked) {
      updatedControls = { ...controls, mobileSettings: { ...controls.mobileSettings, floatingOnlyOnAd: false, floatOnViewPlayer: true } };

    } else if (name === 'floatingOnlyOnAd' && checked) {
      updatedControls = { ...controls, mobileSettings: { ...controls.mobileSettings, floatOnViewPlayer: false, floatingOnlyOnAd: true } };
    }
    setControls(updatedControls);
    props.selectedItems(updatedControls);
  };

  return (
    <div>
      {/* <PlayerSettings /> */}
      <div className="player-content">
        <Row >
          <Col lg="12" className="p-0">
            <div className="vertical-wizard wizard clearfix vertical">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classnames({ current: activeTab === 1 })}
                  >
                    <NavLink
                      className={classnames({ current: activeTab === 1 })}
                      onClick={() => {
                        setactiveTab(1)
                      }}
                    >
                      <span className="number">3.1</span> Player Aesthetics
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({ current: activeTab === 2 })}
                  >
                    <NavLink
                      className={classnames({ active: activeTab === 2 })}
                      onClick={() => {
                        setactiveTab(2)
                      }}
                    >
                      <span className="number ">3.2</span> Player Viewability Config
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({ current: activeTab === 3 })}
                  >
                    <NavLink
                      className={classnames({ active: activeTab === 3 })}
                      onClick={() => {
                        setactiveTab(3)
                      }}
                    >
                      <span className="number">3.3</span> Player Behaviour Config
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({ current: activeTab === 4 })}
                  >
                    <NavLink
                      className={classnames({ active: activeTab === 4 })}
                      onClick={() => {
                        setactiveTab(4)
                      }}
                    >
                      <span className="number">3.4</span> Player Scale Config
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({ current: activeTab === 5 })}
                  >
                    <NavLink
                      className={classnames({ active: activeTab === 5 })}
                      onClick={() => {
                        setactiveTab(5)
                      }}
                    >
                      <span className="number">3.5</span> Player Appearance Config
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix " style={{ height: '55vh' }}>
                <TabContent activeTab={activeTab} className="body">
                  <TabPane tabId={1}>
                    <h5 className="">Player Aesthetics</h5>
                    <div className="row mb-4">
                      <div className="col-md-5">

                        <div className="form-group mt-3">

                          <input type="checkbox" id="prevBtn" className="form-check-input mt-1 p-0" checked={controls.previousNextButton} onChange={handleCheck} name="previousNextButton" />
                          <label htmlFor="prevBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Previous/Next Button</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="backwordBtn" className="form-check-input mt-1 p-0" checked={controls.backwordForwordButton} onChange={handleCheck} name="backwordForwordButton" />
                          <label htmlFor="backwordBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Backword/Forword Button</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="playpauseBtn" className="form-check-input  mt-1 p-0" checked={controls.playPauseButton} onChange={handleCheck} name="playPauseButton" disabled />
                          <label htmlFor="playpauseBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Play/Pause Button</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="qualitySelector" className='form-check-input mt-1 p-0' checked={controls.qualitySelector} onChange={handleCheck} name="qualitySelector" />
                          <label htmlFor="qualitySelector" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Quality Selector</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="playback" className='form-check-input mt-1 p-0' checked={controls.playbackRates} onChange={handleCheck} name="playbackRates" />
                          <label htmlFor="playback" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Playback Speed</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="showSocialShare" className='form-check-input mt-1 p-0' checked={controls.showSocialShare} onChange={handleCheck} name="showSocialShare" />
                          <label htmlFor="showSocialShare" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Social Share options</label>
                        </div>
                        {controls.showSocialShare &&
                          <>
                            <div className="form-group mt-3">
                              <input type="checkbox" id="views" className='form-check-input mt-1 p-0' checked={controls.views} onChange={handleCheck} name="views" />
                              <label htmlFor="views" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Views</label>
                            </div>
                            <div className="form-group mt-3">
                              <input type="checkbox" id="like" className='form-check-input mt-1 p-0' checked={controls.like} onChange={handleCheck} name="like" />
                              <label htmlFor="like" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Like</label>
                            </div>
                            <div className="form-group mt-3">
                              <input type="checkbox" id="share" className='form-check-input mt-1 p-0' checked={controls.share} onChange={handleCheck} name="share" />
                              <label htmlFor="share" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Share</label>
                            </div>
                          </>
                        }
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 ">

                        <div className="form-group mt-3">
                          <input type="checkbox" id="fullscreen" className="form-check-input  mt-1 p-0" checked={controls.fullscreenToggle} onChange={handleCheck} name="fullscreenToggle" disabled />
                          <label htmlFor="fullscreen" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Fullscreen Button</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="duration" className="form-check-input  mt-1 p-0" checked={controls.duration} onChange={handleCheck} name="duration" />
                          <label htmlFor="duration" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Duration</label>
                        </div>
                        <div className="form-group mt-3">
                          <input id="soundBtn" type="checkbox" className="form-check-input  mt-1 p-0" checked={controls.soundButton} onChange={handleCheck} name="soundButton" />
                          <label htmlFor="soundBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Sound Button</label>
                        </div>

                        <div className="form-group mt-3">
                          <input type="checkbox" id="nextBtn" className="form-check-input mt-1 p-0 " checked={controls.showPlaylist} onChange={handleCheck} name="showPlaylist" />
                          <label htmlFor="nextBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Show Playlist</label>
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">Playlist Position</Label>
                          <Input
                            type="select"
                            value={controls.playlistPosition}
                            onChange={handleChange}
                            name="playlistPosition"
                            className="col-5"
                            disabled={!controls.showPlaylist}
                          >
                            <option value="bottom">Bottom</option>
                            <option value="outer-right">Outer-Right</option>
                            <option value="outer-left">Outer-Left</option>
                            <option value="inner-left">Inner-Left</option>
                            <option value="inner-right">Inner-Right</option>
                          </Input>
                        </div>
                      </div>

                    </div>
                  </TabPane>
                  <TabPane tabId={2}>
                    <h5 className="">Player Viewability Configuration</h5>
                    <div className="row mb-4">
                      <div className="col-md-5">
                        <div className="form-group mt-3">
                          <input type="checkbox" id="loadOnAdBlock" className="form-check-input  mt-1 p-0" checked={controls.loadOnAdBlock} onChange={handleCheck} name="loadOnAdBlock" disabled />
                          <label htmlFor="loadOnAdBlock" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Load the player on Adblock</label>
                        </div>

                        <div className="form-group mt-3">
                          <input type="checkbox" id="playerLoadOnPageView" className="form-check-input  mt-1 p-0" checked={controls.playerLoadOnPageView} onChange={handleCheck} name="playerLoadOnPageView" disabled />
                          <label htmlFor="playerLoadOnPageView" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Load Player On Page Load</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="continueRequestAd" className="form-check-input mt-1 p-0" checked={controls.continueRequestAd} onChange={handleCheck} name="continueRequestAd" disabled />
                          <label htmlFor="continueRequestAd" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Continue RequestAd on Player Pause</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="pauseOnUnseen" className="form-check-input mt-1 p-0" checked={controls.pauseOnUnseen} onChange={handleCheck} name="pauseOnUnseen" />
                          <label htmlFor="pauseOnUnseen" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Pause Player On Unseen</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="continuePlayerOnTab" className="form-check-input mt-1 p-0" checked={controls.continuePlayerOnTab} onChange={handleCheck} name="continuePlayerOnTab" />
                          <label htmlFor="continuePlayerOnTab" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Continue Player on TabView</label>
                        </div>
                        <div className="form-group mt-3">
                          <input id="playonview" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.playOnView} onChange={handleCheck} name="playOnView" />
                          <label htmlFor="playonview" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Play On View <i className='bx bxs-info-circle font-size-12' id="playonviewi" ></i></label>

                          <ReactTooltip
                            anchorId="playonviewi"
                            place="top"
                            variant="info"

                            content="By enabling play on view,Please give Percentage Player Start on View "
                          />
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">Player Start on View</Label>
                          <InputGroup>
                            <Input
                              type="select"
                              value={controls.startOnView}
                              onChange={handleChange}
                              name="startOnView"
                              className="col-5"
                              disabled={!controls.playOnView}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="90">90</option>
                            </Input>
                            <div className="input-group-text">%</div>
                          </InputGroup>
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">RequestAd On View</Label>
                          <InputGroup>
                            <Input
                              type="select"
                              value={controls.requestAdOnView}
                              onChange={handleChange}
                              name="requestAdOnView"
                              className="col-5"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="90">90</option>
                            </Input>
                            <div className="input-group-text">%</div>
                          </InputGroup>
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">PlayAd On View</Label>
                          <InputGroup>
                            <Input
                              type="select"
                              value={controls.playAdOnView}
                              onChange={handleChange}
                              name="playAdOnView"
                              className="col-5"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="90">90</option>
                            </Input>
                            <div className="input-group-text">%</div>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 ">

                      </div>

                    </div>
                  </TabPane>
                  <TabPane tabId={3}>
                    <h5 className=" mb-3">Player Behaviour Configuration</h5>
                    <div className="row mb-1">
                      <div className="col-md-5">
                        <div className="form-group mb-3">
                          <input type="checkbox" id="checkTabInView" className="form-check-input mt-1 p-0" checked={controls.checkTabInView} onChange={handleCheck} name="checkTabInView" disabled />
                          <label className="col-9 mt-0 mb-0 m-3" htmlFor='checkTabInView' style={{ cursor: "pointer" }} >Check Tab In View </label>
                        </div>
                        <div className="form-group mt-3 mb-3">
                          <input id="autoplay" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.autoplay} onChange={handleCheck} name="autoplay" />
                          <label htmlFor="autoplay" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Autoplay <i className='bx bxs-info-circle font-size-12' id="autoplayi" ></i></label>
                          <ReactTooltip
                            anchorId="autoplayi"
                            place="top"
                            variant="info"

                            content="By enabling autoplay, Click To Play and User Consent will be disabled"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="clickToPlay" className="form-check-input  mt-1 p-0" checked={controls.clickToPlay} onChange={handleCheck} name="clickToPlay" />
                          <label htmlFor="clickToPlay" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Click to Play</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="clickToPlayRequestAd" className="form-check-input mt-1 p-0" checked={controls.clickToPlayRequestAd} onChange={handleCheck} name="clickToPlayRequestAd" />
                          <label htmlFor="clickToPlayRequestAd" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Click to Play with Request Ad</label>
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">Click to Play with Request Ad Timer</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              onKeyPress={allowOnlyNumbers}
                              value={controls.clickToPlayRequestAdTime}
                              onChange={handleChangeNumbers}
                              name="clickToPlayRequestAdTime"
                              disabled={!controls.clickToPlayRequestAd}
                            />
                            <div className="input-group-text">Seconds</div>
                          </InputGroup>
                        </div>
                        <div className="form-group mt-3 mb-3">
                          <Label className="form-label">Click to Play with Request Ad View Port</Label>
                          <InputGroup>
                            <Input
                              type="select"
                              value={controls.clickToPlayRequestAdViewport}
                              onChange={handleChange}
                              name="clickToPlayRequestAdViewport"
                              disabled={!controls.clickToPlayRequestAd}
                            >
                              <option value="">Select</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="90">90</option>
                            </Input>
                            <div className="input-group-text">%</div>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row mb-1">
                      <div className="col-md-5">
                      <div className="mb-2">
                          <div className="form-check form-switch form-check-success form-switch-md   " >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="muted"
                              checked={controls.muted} onChange={handleCheck} name="muted"
                            />
                            <label className="form-check-label " htmlFor="muted">
                              <h6 className="heading6 mt-1" style={{ color: !controls.muted ? '#f46a6a' : '#34c38f' }}>{!controls.muted ? 'Muted' : 'Sound On'}</h6>
                            </label>
                          </div>
                        </div>
                        {/* <div className="form-group mt-3">
                          <input type="checkbox" id="muted" className="form-check-input mt-1 p-0" checked={controls.muted} onChange={handleCheck} name="muted" />
                          <label htmlFor="muted" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >{controls.muted ? 'Muted' : 'Sound On'}</label>
                        </div> */}
                        <div className="mb-3">
                          <Label className="form-label">
                            Volume On Player Interaction
                          </Label>
                          {/* <label className=''>Volume On Player Interaction </label><br /> */}
                          <InputGroup>
                            <Input
                              type="text"
                              label="volume"
                              value={controls.volumePlayer}
                              onChange={handleChange}
                              name="volumePlayer"
                              onKeyPress={allowOnlyNumbers}
                              disabled={!controls.muted}
                            />
                            <div className="input-group-text">%</div>

                          </InputGroup>
                          {/* <input id="typeinp" type="range" name="volumePlayer" value={controls.volumePlayer} min="0" max="100" step="1" onInput={handleChange} disabled={!controls.muted} /> */}
                        </div>
                        <div className="mb-2">
                          <div className="form-check form-switch form-check-success form-switch-md   " >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="unMuteOnEvents"
                              checked={controls.unMuteOnEvents} onChange={handleCheck} name="unMuteOnEvents"
                            />
                            <label className="form-check-label " htmlFor="unMuteOnEvents">
                              <h6 className="heading6 mt-1">UnMute Player Based on Event</h6>
                            </label>
                          </div>
                        </div>
                        <div className="mb-3">
                          {/* <label className=''> Volume On Page Interaction : {controls.volumePageInteraction}%</label><br /> */}
                          <Label className="form-label">
                            Volume On Page Interaction
                          </Label>
                          <InputGroup>
                            <Input
                              type="text"
                              label="volume"
                              value={controls.volumePageInteraction}
                              onChange={handleChange}
                              name="volumePageInteraction"
                              onKeyPress={allowOnlyNumbers}
                              disabled={!controls.unMuteOnEvents}
                            />
                            <div className="input-group-text">%</div>

                          </InputGroup>
                          {/* <input id="typeinp" type="range" name="volumePageInteraction" value={controls.volumePageInteraction} min="0" max="100" step="1" onInput={handleChange} disabled={!controls.muted} /> */}
                        </div>
                        <div className="form-group row mt-3  mb-3 ml-3">
                          <div className="form-group ">
                            <Label className="form-label">
                              Events for Unmute Player
                            </Label>
                            <MultiSelect
                              options={[{ label: 'On Hover', value: 'on_hover' }, { label: 'On Scroll', value: 'on_scroll' }, { label: 'On Keyboard Clicks', value: 'on_keyboard_click' }, { label: 'On Page Clicks ', value: 'on_page_cilck' }]}
                              value={selectedEvents}
                              onChange={handleSelectEvent}
                              overrideStrings={{ "selectSomeItems": "Please Select Events" }}
                              disabled={!controls.unMuteOnEvents}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row mb-1">
                      <div className="col-md-5">
                        <div className="mb-2 ">
                          <div className="form-check form-switch form-check-success form-switch-md   " >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="user_consent"
                              checked={controls.user_consent} onChange={handleCheck} name="user_consent"
                            />
                            <label className="form-check-label " htmlFor="user_consent">
                              <h6 className="heading6 mt-1">User Consent <i className='bx bxs-info-circle font-size-12' id="userconsent" ></i></h6>
                            </label>
                            <ReactTooltip
                              anchorId="userconsent"
                              place="top"
                              variant="info"

                              content="User consent Popup"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2  ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Title of User Consent</Label>
                          <Input
                            type="text"
                            name="user_consent_title"
                            value={controls.user_consent_title}
                            onChange={handleChange}
                            readOnly={!controls.user_consent}
                          />
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">User Consent Heading Text</Label>
                          <Input
                            type="text"
                            name="user_heading_text"
                            value={controls.user_heading_text}
                            onChange={handleChange}
                            readOnly={!controls.user_consent}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Confirmation Button Text </Label>
                          <Input
                            type="text"
                            name="user_consent_button_text"
                            value={controls.user_consent_button_text}
                            onChange={handleChange}
                            readOnly={!controls.user_consent}
                          />
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Confirmation Button Color /Color Hexa Code</Label>
                          <Input
                            type="text"
                            name="user_consent_button_color"
                            value={controls.user_consent_button_color}
                            onChange={handleChange}
                            readOnly={!controls.user_consent}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Cancel Text</Label>
                          <Input
                            type="text"
                            name="user_consent_cancel_text"
                            value={controls.user_consent_cancel_text}
                            onChange={handleChange}
                            readOnly={!controls.user_consent}
                          />
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">User Consent On View Percentage</Label>
                          <InputGroup>
                            <Input
                              type="select"
                              value={controls.user_consent_viewOnPercentage}
                              onChange={handleChange}
                              name="user_consent_viewOnPercentage"
                              disabled={!controls.user_consent}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="90">90</option>
                            </Input>
                            <div className="input-group-text">%</div>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 mb-3 ml-4">
                      {/* <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Close Button Position</Label>
                          <Input
                            type="select"
                            className="form-select"
                            value={controls.user_consent_close_position}
                            onChange={handleChange}
                            name="user_consent_close_position"
                            disabled={!controls.user_consent}
                          >
                            <option value="top-left">Top Left</option>
                            <option value="top-right">Top Right</option>
                          </Input>
                        </div>
                      </div>
                      <div className="col-md-1"></div> */}
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Timer to show Close Button</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              name="user_consent_close_button_timer"
                              value={controls.user_consent_close_button_timer}
                              onChange={handleChangeNumbers}
                              readOnly={!controls.user_consent}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">Seconds</div>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="mb-3">
                      <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizemdskip"
                          checked={controls.skipButton} onChange={handleCheck} name="skipButton"
                        />
                        <label className="form-check-label " htmlFor="customSwitchsizemdskip">
                          <h6 className="heading6 mt-1">Skip Button</h6>
                        </label>
                      </div>
                    </div>
                    <div className="form-group row mt-1  mb-3 ml-3">
                      <div className="row mt-2 ml-4 mb-3">
                        <div className="col-lg-5">
                          <div className="form-group ">
                            <Label className="form-label">
                              Skip Timer
                            </Label>
                            <InputGroup>
                              <Input
                                type="text"
                                label="skipTimer"
                                value={controls.skipTimer}
                                onKeyPress={allowOnlyNumbers}
                                onChange={handleChangeNumbers}
                                name="skipTimer"
                                readOnly={!controls.skipButton}
                              />
                              <div className="input-group-text">Seconds</div>
                            </InputGroup>

                          </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5">
                          <div className="form-group ">
                            <Label className="form-label">
                              Skip Text
                            </Label>
                            <Input
                              type="text"
                              label="skipText"
                              value={controls.skipText}
                              onChange={handleChange}
                              name="skipText"
                              readOnly={!controls.skipButton}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizemdskips"
                          checked={controls.autoSkip} onChange={handleCheck} name="autoSkip"
                        />
                        <label className="form-check-label " htmlFor="customSwitchsizemdskips">
                          <h6 className="heading6 mt-1">Enable Auto Skip</h6>
                        </label>
                      </div>
                    </div>
                    <div className="form-group row mt-1  mb-3 ml-3">
                      <div className="row mt-2 ml-4 mb-3">
                        <div className="col-lg-5">
                          <div className="form-group ">
                            <Label className="form-label">
                              Time To Skip
                            </Label>
                            <InputGroup>
                              <Input
                                type="text"
                                label="timeToSkip"
                                value={controls.timeToSkip}
                                onChange={handleChangeNumbers}
                                name="timeToSkip"
                                readOnly={!controls.autoSkip}
                                onKeyPress={allowOnlyNumbers}
                              />
                              <div className="input-group-text">Seconds</div>
                            </InputGroup>

                          </div>
                        </div>
                        <div className="col-lg-1"></div>
                        {/* <div className="col-lg-5">
                <div className="form-group ">
                  <Label className="form-label">
                    Show Auto Skip Buttons
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      label="showAutoSkipButtons"
                      value={controls.showAutoSkipButtons}
                      onChange={handleChange}
                      name="showAutoSkipButtons"
                      readOnly={!controls.autoSkip}
                    />
                    <div className="input-group-text">Seconds</div>
                  </InputGroup>
                </div>
              </div> */}

                        {/* <div className="row mt-2 ml-4 mb-3"> */}
                        <div className="col-lg-5">
                          <div className="form-group ">
                            <Label className="form-label">
                              Skip Text
                            </Label>
                            <Input
                              type="text"
                              label="autoSkipText"
                              value={controls.autoSkipText}
                              onChange={handleChange}
                              name="autoSkipText"
                              readOnly={!controls.autoSkip}
                            />
                          </div>
                        </div>
                        {/* </div> */}
                      </div>

                    </div>
                  </TabPane>
                  <TabPane tabId={4}>
                    <h5 className="">Player Scale Configuration</h5>
                    <div className="row mb-4">
                      <div className="col-md-5">
                        <div className="form-group mt-3">
                          <Label className="form-label">Margin Left</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              onKeyPress={allowOnlyNumbers}
                              value={controls.marginLeft}
                              onChange={handleChangeNumbers}
                              name="marginLeft"
                            />
                            <div className="input-group-text">px</div>
                          </InputGroup>
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">Margin Right</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              onKeyPress={allowOnlyNumbers}
                              value={controls.marginRight}
                              onChange={handleChangeNumbers}
                              name="marginRight"
                            />
                            <div className="input-group-text">px</div>
                          </InputGroup>
                        </div>
                        {/* <div className="form-group mt-3">
                          <Label className="form-label">Play OnView Percentage</Label>
                          <InputGroup>
                            <Input
                              type="select"
                              value={controls.playOnViewPercentage}
                              onChange={handleChange}
                              name="playOnViewPercentage"
                              className="col-5"
                              disabled={!controls.playOnView}
                            >
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                            </Input>
                            <div className="input-group-text">%</div>
                          </InputGroup>
                        </div> */}
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group mt-3">
                          <Label className="form-label">Margin Top</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              onKeyPress={allowOnlyNumbers}
                              value={controls.marginTop}
                              onChange={handleChangeNumbers}
                              name="marginTop"
                            />
                            <div className="input-group-text">px</div>
                          </InputGroup>
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">Margin Bottom</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              onKeyPress={allowOnlyNumbers}
                              value={controls.marginBottom}
                              onChange={handleChangeNumbers}
                              name="marginBottom"
                            />
                            <div className="input-group-text">px</div>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row  mt-3 ml-3 mb-3">
                      {/* <div className="mb-1">
              <div className="form-check form-switch form-check-success form-switch-md mt-2  col-12 col-md-6" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inResposiveBtn"
                  checked={controls.isResponsive} onChange={handleCheck} name="isResponsive"
                />
                <label className="form-check-label " htmlFor="inResposiveBtn">
                  <h5 className="heading5 mt-1">Responsive</h5>
                </label>
              </div>
            </div> */}

                      {(props.settingsData.PlayerSettingType === 'desktop' || props.settingsData.PlayerSettingType === 'all') &&
                        <div className='col-lg-5'>
                          <Label className="form-label">
                            Desktop Player Size
                          </Label>

                          <Input
                            type="select"
                            className="form-select"
                            value={controls.playerSize}
                            onChange={handleChange}
                            name="playerSize"
                          >
                            <option value="desktop">Desktop-Default (640x360)</option>
                            <option value="custom">Custom</option>
                          </Input>
                          {controls.playerSize === 'custom' &&
                            <div className="row mt-2 ml-4">
                              <div className="col-lg-5">
                                <div className="form-group ">
                                  <Label className="form-label">
                                    Width
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      label="Width"
                                      value={controls.playerWidth}
                                      onChange={handleChangeNumbers}
                                      name="playerWidth"
                                      onKeyPress={allowOnlyNumbers}
                                    />
                                    <div className="input-group-text">px</div>

                                  </InputGroup>
                                </div>
                              </div>
                              <div className="col-lg-1"></div>
                              <div className="col-lg-5">
                                <div className="form-group ">
                                  <Label className="form-label">
                                    Height
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      label="Height"
                                      value={controls.playerHeight}
                                      onChange={handleChangeNumbers}
                                      name="playerHeight"
                                      onKeyPress={allowOnlyNumbers}
                                    />
                                    <div className="input-group-text">px</div>

                                  </InputGroup>
                                </div>
                              </div>


                            </div>
                          }
                        </div>
                      }
                      {(props.settingsData.PlayerSettingType === 'desktop' || props.settingsData.PlayerSettingType === 'all') &&
                        <div className="col-md-1"></div>}
                      {(props.settingsData.PlayerSettingType === 'mobile' || props.settingsData.PlayerSettingType === 'all') &&
                        <div className='col-lg-5 '>
                          <Label className="form-label">
                            Mobile Player Size
                          </Label>
                          <Input
                            type="select"
                            className="form-select"
                            value={controls.mobileSettings.playerSize}
                            onChange={handleChangeMobile}
                            name="playerSize"
                          >
                            <option value="mobile">Mobile-Default (400x225)</option>
                            <option value="custom">Custom</option>
                          </Input>
                          {controls.mobileSettings.playerSize === 'custom' &&
                            <div className="row mt-2 ml-4">
                              <div className="col-lg-5">
                                <div className="form-group ">
                                  <Label className="form-label">
                                    Width
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      label="Width"
                                      value={controls.mobileSettings.playerWidth}
                                      onChange={handleChangeNumbersMobile}
                                      name="playerWidth"
                                      onKeyPress={allowOnlyNumbers}
                                    />
                                    <div className="input-group-text">px</div>

                                  </InputGroup>
                                </div>
                              </div>
                              <div className="col-lg-1"></div>
                              <div className="col-lg-5">
                                <div className="form-group ">
                                  <Label className="form-label">
                                    Height
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      label="Height"
                                      value={controls.mobileSettings.playerHeight}
                                      onChange={handleChangeNumbersMobile}
                                      name="playerHeight"
                                      onKeyPress={allowOnlyNumbers}
                                    />
                                    <div className="input-group-text">px</div>

                                  </InputGroup>
                                </div>
                              </div>


                            </div>
                          }
                        </div>
                      }

                    </div>
                    {props.settingsData.PlayerSettingType === 'all' &&
                      <div className='row mb-4'>
                        <div className='col-md-5'>
                          <div className="form-check form-switch form-check-success form-switch-md" >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizemds"
                              checked={controls.desktopSettingsEnabled} onChange={handleCheck} name="desktopSettingsEnabled"
                            />
                            <label className="form-check-label " htmlFor="customSwitchsizemds">
                              <span color='text-success' style={{ color: controls.desktopSettingsEnabled ? '#34c38f' : '#a3a3a3', marginTop: '' }}>Enable Desktop Player State</span>&nbsp;&nbsp;
                            </label>
                          </div>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-5'>
                          <div className="form-check form-switch form-check-success form-switch-md" >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizemdss"
                              checked={controls.mobileSettingsEnabled} onChange={handleCheck} name="mobileSettingsEnabled"
                            />
                            <label className="form-check-label " htmlFor="customSwitchsizemdss">
                              <span color='text-success' style={{ color: controls.mobileSettingsEnabled ? '#34c38f' : '#a3a3a3', marginTop: '' }}>Enable Mobile Player State</span>&nbsp;&nbsp;
                            </label>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="row mt-3">
                      {(props.settingsData.PlayerSettingType === 'desktop' || props.settingsData.PlayerSettingType === 'all') &&
                        <div className='col-lg-5 mb-3'>
                          <h5>Desktop Player State</h5>
                          <div className="form-group mt-2">
                            <Input
                              type="select"
                              className="form-select"
                              value={controls.playerState}
                              onChange={handleChange}
                              name="playerState"
                              disabled={!controls.desktopSettingsEnabled}
                            >
                              <option value="inRead"  >
                                In Read
                              </option>
                              <option value="floating"  >
                                Floating
                              </option>
                            </Input>
                          </div>

                        </div>
                      }
                      {(props.settingsData.PlayerSettingType === 'desktop' || props.settingsData.PlayerSettingType === 'all') &&
                        <div className="col-md-1"></div>}
                      {(props.settingsData.PlayerSettingType === 'mobile' || props.settingsData.PlayerSettingType === 'all') &&
                        <div className='col-lg-5 mb-3'>
                          <h5>Mobile Player State</h5>
                          <div className="form-group mt-2">
                            <Input
                              type="select"
                              className="form-select"
                              value={controls.mobileSettings.playerState}
                              onChange={handleChangeMobile}
                              name="playerState"
                              disabled={!controls.mobileSettingsEnabled}
                            >
                              <option value="inRead"  >
                                In Read
                              </option>
                              <option value="floating"  >
                                Floating
                              </option>
                            </Input>
                          </div>

                        </div>
                      }
                    </div>
                    {
                      <div className="row">
                        {controls.playerState === "floating" && (props.settingsData.PlayerSettingType === 'desktop' || props.settingsData.PlayerSettingType === 'all') &&
                          <div className="col-md-5">

                            <div>
                              <div className="mb-3">
                                <div className="form-group mt-3">
                                  <input type="checkbox" id="floatingCloseBtn" className="form-check-input mt-1 p-0" checked={controls.floatingCloseButton} onChange={handleCheck} name="floatingCloseButton" disabled={!controls.desktopSettingsEnabled} />
                                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="floatingCloseBtn">Floating Close Button</label>
                                </div>
                                <div className="form-group mt-3">
                                  <input type="checkbox" id="floatingOnlyOnAd" className="form-check-input mt-1 p-0" checked={controls.floatingOnlyOnAd} onChange={handleCheck} name="floatingOnlyOnAd" disabled={!controls.desktopSettingsEnabled} />
                                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="floatingOnlyOnAd">Float Only On Ad</label>
                                </div>
                                <div className="form-group mt-3">
                                  <input type="checkbox" id="floatOnViewPlayer" className="form-check-input mt-1 p-0" checked={controls.floatOnViewPlayer} onChange={handleCheck} name="floatOnViewPlayer" disabled={!controls.desktopSettingsEnabled} />
                                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="floatOnViewPlayer">Float On View</label>
                                </div>
                                <div className="form-group mt-3">
                                  <input type="checkbox" id="floatOnlyBottom" className="form-check-input mt-1 p-0" checked={controls.floatOnlyBottom} onChange={handleCheck} name="floatOnlyBottom" disabled={!controls.desktopSettingsEnabled} />
                                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="floatOnlyBottom">Float Only On Bottom</label>
                                </div>
                              </div>
                              <h6>Desktop Floating Position</h6>
                              <div className="form-group mt-3">
                                <Label className="form-label">Margin Left</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={controls.floatingMarginLeft}
                                    onChange={handleChangeNumbers}
                                    name="floatingMarginLeft"
                                    onKeyPress={allowOnlyNumbers}
                                    readOnly={!controls.desktopSettingsEnabled}
                                  />
                                  <div className="input-group-text">px</div>

                                </InputGroup>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Margin Right</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={controls.floatingMarginRight}
                                    onChange={handleChangeNumbers}
                                    name="floatingMarginRight"
                                    onKeyPress={allowOnlyNumbers}
                                    readOnly={!controls.desktopSettingsEnabled}
                                  />
                                  <div className="input-group-text">px</div>

                                </InputGroup>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Margin Top</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={controls.floatingMarginTop}
                                    onChange={handleChangeNumbers}
                                    name="floatingMarginTop"
                                    onKeyPress={allowOnlyNumbers}
                                    readOnly={!controls.desktopSettingsEnabled}
                                  />
                                  <div className="input-group-text">px</div>

                                </InputGroup>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Margin Bottom</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={controls.floatingMarginBottom}
                                    onChange={handleChangeNumbers}
                                    name="floatingMarginBottom"
                                    onKeyPress={allowOnlyNumbers}
                                    readOnly={!controls.desktopSettingsEnabled}
                                  />
                                  <div className="input-group-text">px</div>

                                </InputGroup>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Floating Placement</Label>
                                <Input
                                  type="select"
                                  className="form-select"
                                  value={controls.floatingPlacement}
                                  onChange={handleChange}
                                  name="floatingPlacement"
                                  disabled={!controls.desktopSettingsEnabled}
                                >
                                  <option value="top-left">top-left</option>
                                  <option value="top-right">top-right</option>
                                  <option value="bottom-left">bottom-left</option>
                                  <option value="bottom-right">bottom-right</option>
                                </Input>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Z-index</Label>
                                <Input
                                  type="text"
                                  value={controls.floatingZindex}
                                  onChange={handleChangeNumbers}
                                  name="floatingZindex"
                                  onKeyPress={allowOnlyNumbers}
                                  readOnly={!controls.desktopSettingsEnabled}
                                />
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Floating Size</Label>
                                <Input
                                  type="select"
                                  className="form-select"
                                  value={controls.floatingSize}
                                  onChange={handleChange}
                                  name="floatingSize"
                                  disabled={!controls.desktopSettingsEnabled}
                                >
                                  <option value="" disabled >
                                    Select Floating Size Type
                                  </option>
                                  <option value="Pixels">Height And Width</option>
                                  <option value="vhandvw">Viewport Percentage</option>
                                </Input>
                              </div>
                              {
                                controls.floatingSize === 'Pixels' &&
                                <div>
                                  <div className="form-group mt-3">
                                    <Label className="form-label">Height</Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        value={controls.floatingSizeHeight}
                                        onChange={handleChangeNumbers}
                                        name="floatingSizeHeight"
                                        onKeyPress={allowOnlyNumbers}
                                        readOnly={!controls.desktopSettingsEnabled}
                                      />
                                      <div className="input-group-text">px</div>

                                    </InputGroup>

                                  </div>
                                  <div className="form-group mt-3">
                                    <Label className="form-label">Width</Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        value={controls.floatingSizeWidth}
                                        onChange={handleChangeNumbers}
                                        name="floatingSizeWidth"
                                        onKeyPress={allowOnlyNumbers}
                                        readOnly={!controls.desktopSettingsEnabled}
                                      />
                                      <div className="input-group-text">px</div>
                                    </InputGroup>
                                  </div>
                                </div>
                              }
                              {
                                controls.floatingSize === 'vhandvw' &&
                                <div>
                                  <div className="form-group mt-3">
                                    <Label className="form-label">Height </Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        value={controls.floatingSizeVH}
                                        onChange={handleChangeNumbers}
                                        name="floatingSizeVH"
                                        onKeyPress={allowOnlyNumbers}
                                        readOnly={!controls.desktopSettingsEnabled}
                                      />
                                      <div className="input-group-text">%</div>
                                    </InputGroup>
                                  </div>
                                  <div className="form-group mt-3">
                                    <Label className="form-label">Width </Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        value={controls.floatingSizeVW}
                                        onChange={handleChangeNumbers}
                                        name="floatingSizeVW"
                                        onKeyPress={allowOnlyNumbers}
                                        readOnly={!controls.desktopSettingsEnabled}
                                      />
                                      <div className="input-group-text">%</div>
                                    </InputGroup>
                                  </div>
                                </div>
                              }
                            </div>

                          </div>
                        }

                        {(props.settingsData.PlayerSettingType === 'desktop' || props.settingsData.PlayerSettingType === 'all') &&
                          <div className="col-md-1"></div>}
                        {controls.mobileSettings.playerState === "floating" && (props.settingsData.PlayerSettingType === 'mobile' || props.settingsData.PlayerSettingType === 'all') &&
                          <div className="col-md-5">

                            <div>
                              <div className="mb-3 ">
                                <div className="form-group mt-3">
                                  <input type="checkbox" id="mobileSettingsfloatingCloseBtn" className="form-check-input mt-1 p-0" checked={controls.mobileSettings.floatingCloseButton} onChange={handleCheckMobile} name="floatingCloseButton" disabled={!controls.mobileSettingsEnabled} />
                                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="mobileSettingsfloatingCloseBtn">Floating Close Button</label>
                                </div>
                                <div className="form-group mt-3">
                                  <input type="checkbox" id="mobileSettingsfloatingOnlyOnAd" className="form-check-input mt-1 p-0" checked={controls.mobileSettings.floatingOnlyOnAd} onChange={handleCheckMobile} name="floatingOnlyOnAd" disabled={!controls.mobileSettingsEnabled} />
                                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="mobileSettingsfloatingOnlyOnAd">Float Only On Ad</label>
                                </div>
                                <div className="form-group mt-3">
                                  <input type="checkbox" id="mobileSettingsfloatOnViewPlayer" className="form-check-input mt-1 p-0" checked={controls.mobileSettings.floatOnViewPlayer} onChange={handleCheckMobile} name="floatOnViewPlayer" disabled={!controls.mobileSettingsEnabled} />
                                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="mobileSettingsfloatOnViewPlayer">Float On View</label>
                                </div>
                                <div className="form-group mt-3">
                                  <input type="checkbox" id="mobileSettingsfloatOnlyBottom" className="form-check-input mt-1 p-0" checked={controls.mobileSettings.floatOnlyBottom} onChange={handleCheckMobile} name="floatOnlyBottom" disabled={!controls.mobileSettingsEnabled} />
                                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="mobileSettingsfloatOnlyBottom">Float Only On Bottom</label>
                                </div>
                              </div>
                              <h6>Mobile Floating Position</h6>
                              <div className="form-group mt-3">
                                <Label className="form-label">Margin Left</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={controls.mobileSettings.floatingMarginLeft}
                                    onChange={handleChangeNumbersMobile}
                                    name="floatingMarginLeft"
                                    onKeyPress={allowOnlyNumbers}
                                    readOnly={!controls.mobileSettingsEnabled}
                                  />
                                  <div className="input-group-text">px</div>

                                </InputGroup>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Margin Right</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={controls.mobileSettings.floatingMarginRight}
                                    onChange={handleChangeNumbersMobile}
                                    name="floatingMarginRight"
                                    onKeyPress={allowOnlyNumbers}
                                    readOnly={!controls.mobileSettingsEnabled}
                                  />
                                  <div className="input-group-text">px</div>

                                </InputGroup>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Margin Top</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={controls.mobileSettings.floatingMarginTop}
                                    onChange={handleChangeNumbersMobile}
                                    name="floatingMarginTop"
                                    onKeyPress={allowOnlyNumbers}
                                    readOnly={!controls.mobileSettingsEnabled}
                                  />
                                  <div className="input-group-text">px</div>
                                </InputGroup>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Margin Bottom</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={controls.mobileSettings.floatingMarginBottom}
                                    onChange={handleChangeNumbersMobile}
                                    name="floatingMarginBottom"
                                    onKeyPress={allowOnlyNumbers}
                                    readOnly={!controls.mobileSettingsEnabled}
                                  />
                                  <div className="input-group-text">px</div>
                                </InputGroup>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Floating Placement</Label>
                                <Input
                                  type="select"
                                  className="form-select"
                                  value={controls.mobileSettings.floatingPlacement}
                                  onChange={handleChangeMobile}
                                  name="floatingPlacement"
                                  disabled={!controls.mobileSettingsEnabled}
                                >
                                  <option value="top-left">top-left</option>
                                  <option value="top-right">top-right</option>
                                  <option value="bottom-left">bottom-left</option>
                                  <option value="bottom-right">bottom-right</option>
                                </Input>
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Z-index</Label>
                                <Input
                                  type="text"
                                  value={controls.mobileSettings.floatingZindex}
                                  onChange={handleChangeNumbersMobile}
                                  name="floatingZindex"
                                  onKeyPress={allowOnlyNumbers}
                                  readOnly={!controls.mobileSettingsEnabled}
                                />
                              </div>
                              <div className="form-group mt-3">
                                <Label className="form-label">Floating Size</Label>
                                <Input
                                  type="select"
                                  className="form-select"
                                  value={controls.mobileSettings.floatingSize}
                                  onChange={handleChangeMobile}
                                  name="floatingSize"
                                  disabled={!controls.mobileSettingsEnabled}
                                >
                                  <option value="" disabled >
                                    Select Floating Size Type
                                  </option>
                                  <option value="Pixels">Height And Width</option>
                                  <option value="vhandvw">Viewport Percentage</option>
                                </Input>
                              </div>
                              {
                                controls.mobileSettings.floatingSize === 'Pixels' &&
                                <div>
                                  <div className="form-group mt-3">
                                    <Label className="form-label">Height</Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        value={controls.mobileSettings.floatingSizeHeight}
                                        onChange={handleChangeNumbersMobile}
                                        name="floatingSizeHeight"
                                        onKeyPress={allowOnlyNumbers}
                                        readOnly={!controls.mobileSettingsEnabled}
                                      />
                                      <div className="input-group-text">px</div>

                                    </InputGroup>

                                  </div>
                                  <div className="form-group mt-3">
                                    <Label className="form-label">Width</Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        value={controls.mobileSettings.floatingSizeWidth}
                                        onChange={handleChangeNumbersMobile}
                                        name="floatingSizeWidth"
                                        onKeyPress={allowOnlyNumbers}
                                        readOnly={!controls.mobileSettingsEnabled}
                                      />
                                      <div className="input-group-text">px</div>
                                    </InputGroup>
                                  </div>
                                </div>
                              }
                              {
                                controls.mobileSettings.floatingSize === 'vhandvw' &&
                                <div>
                                  <div className="form-group mt-3">
                                    <Label className="form-label">Height </Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        value={controls.mobileSettings.floatingSizeVH}
                                        onChange={handleChangeNumbersMobile}
                                        name="floatingSizeVH"
                                        onKeyPress={allowOnlyNumbers}
                                        readOnly={!controls.mobileSettingsEnabled}
                                      />
                                      <div className="input-group-text">%</div>
                                    </InputGroup>
                                  </div>
                                  <div className="form-group mt-3">
                                    <Label className="form-label">Width </Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        value={controls.mobileSettings.floatingSizeVW}
                                        onChange={handleChangeNumbersMobile}
                                        name="floatingSizeVW"
                                        onKeyPress={allowOnlyNumbers}
                                        readOnly={!controls.mobileSettingsEnabled}
                                      />
                                      <div className="input-group-text">%</div>
                                    </InputGroup>
                                  </div>
                                </div>
                              }
                            </div>

                          </div>}

                      </div>
                    }
                    <div className="mb-3 mt-3">
                      <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="ContentIdentifier"
                          checked={controls.contentIdentifierState} onChange={handleCheck} name="contentIdentifierState"
                        />
                        <label className="form-check-label " htmlFor="ContentIdentifier">
                          <h6 className="heading6 mt-1">Player Placement Position</h6>
                        </label>
                      </div>
                    </div>
                    {controls.contentIdentifierState &&
                      <div className="row mt-3 mb-3">
                        <div className='col-lg-5 mb-3'>
                          <Label className="form-label">
                            Content Identifier
                          </Label>
                          <Input
                            type="select"
                            className="form-select"
                            value={controls.contentIdentifier}
                            onChange={handleChange}
                            name="contentIdentifier"
                          >
                            <option value="fixed">Fixed</option>
                            <option value="custom">Custom</option>
                          </Input>
                        </div>
                        <div className="col-md-1"></div>
                        {controls.contentIdentifier === 'fixed' &&
                          <div className='col-lg-5 mb-3'>
                            <Label className="form-label">
                              Content Position
                            </Label>
                            <Input
                              type="select"
                              className="form-select"
                              value={controls.contentPosition}
                              onChange={handleChange}
                              name="contentPosition"
                            >
                              <option value="atf">ATF</option>
                              <option value="midPage">Mid Page</option>
                              <option value="btf">BTF</option>
                            </Input>
                          </div>
                        }
                        {controls.contentIdentifier === 'custom' &&
                          <>
                            <div className='col-lg-5 mb-3'>
                              <Label className="form-label">
                                Selector
                              </Label>
                              <Input
                                type="select"
                                className="form-select"
                                value={controls.contentSelector}
                                onChange={handleChange}
                                name="contentSelector"
                              >
                                <option value="tag">Tag</option>
                                <option value="class">class</option>
                                <option value="id">Id</option>
                                <option value="selector">Selector</option>
                              </Input>
                            </div>

                            <div className='col-lg-5 mb-3'>
                              <Label className="form-label">
                                Attribute Name
                              </Label>
                              <Input
                                type="text"
                                value={controls.contentIdentifierName}
                                onChange={handleChange}
                                name="contentIdentifierName"
                              />
                            </div>
                            <div className="col-md-1"></div>
                            <div className='col-lg-5'>
                              <div>
                                <Label className="form-label">
                                  Attribute Position Index
                                </Label>
                                <Input
                                  type="text"
                                  value={controls.contentIdentifierNo}
                                  onChange={handleChangeNumbers}
                                  name="contentIdentifierNo"
                                  onKeyPress={allowOnlyNumbers}
                                  disabled={controls.contentSelector === 'id'}
                                />
                              </div>
                            </div>
                            <div className='col-lg-5 mb-3'>
                              <Label className="form-label">
                                FallBack Position Identifier
                              </Label>
                              <Input
                                type="select"
                                className="form-select"
                                value={controls.fallBackPositionIdentifier}
                                onChange={handleChange}
                                name="fallBackPositionIdentifier"
                              >
                                <option value='' >Select</option>
                                <option value="atf">ATF</option>
                                <option value="midPage">Mid Page</option>
                                <option value="btf">BTF</option>
                              </Input>
                            </div>
                          </>
                        }
                      </div>
                    }
                  </TabPane>
                  <TabPane tabId={5}>
                    <h5 className="mb-3">Player Appearance Configuration</h5>
                    <div className="row mb-4">
                      <div className="col-md-5">
                        <div className="form-group mt-3">
                          <input type="checkbox" id="autoContinueNextContent" className="form-check-input  mt-1 p-0" checked={controls.autoContinueNextContent} onChange={handleCheck} name="autoContinueNextContent" disabled />
                          <label htmlFor="autoContinueNextContent" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Auto Continue to Next Content</label>
                        </div>

                        <div className="form-group mt-3">
                          <input type="checkbox" id="loopPlaylistContent" className="form-check-input  mt-1 p-0" checked={controls.loopPlaylistContent} onChange={handleCheck} name="loopPlaylistContent" disabled />
                          <label htmlFor="loopPlaylistContent" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Loop Playlist Content</label>
                        </div>
                        <div className="form-group mt-3">
                          <input type="checkbox" id="advertisingLabel" className="form-check-input mt-1 p-0" checked={controls.advertisingLabel} onChange={handleCheck} name="advertisingLabel" />
                          <label htmlFor="advertisingLabel" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Enable Advertising Label</label>
                        </div>

                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 ">
                        <div className="form-group mt-3">
                          <Label className="form-label">Advertising Label</Label>
                          <Input
                            type="text"
                            value={controls.advertisingLabelText}
                            onChange={handleChange}
                            name="advertisingLabelText"
                            className="col-5"
                          >
                          </Input>
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">Player Size Management</Label>
                          <Input
                            type="select"
                            value={controls.playerSizeMgmt}
                            onChange={handleChange}
                            name="playerSizeMgmt"
                            className="col-5"
                          >
                            <option value="responsive">Responsive</option>
                            <option value="fixed">Fixed</option>
                          </Input>
                        </div>
                      </div>

                    </div>
                    <div className="mb-3">
                      <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizemdclose"
                          checked={controls.closeBtn} onChange={handleCheck} name="closeBtn"
                        />
                        <label className="form-check-label " htmlFor="customSwitchsizemdclose">
                          <h6 className="heading6 mt-1">Close Button</h6>
                        </label>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Position</Label>
                          <Input
                            type="select"
                            className="form-select"
                            value={controls.closeBtn_position}
                            onChange={handleChange}
                            name="closeBtn_position"
                            disabled={!controls.closeBtn}
                          >
                            {/* <option value="inner-left">Inner-left</option>
                  <option value="inner-right">Inner-right</option> */}
                            <option value="outer-left">Outer-left</option>
                            <option value="outer-right">Outer-right</option>
                          </Input>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Height</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              value={controls.closeBtn_height}
                              onChange={handleChangeNumbers}
                              name="closeBtn_height"
                              readOnly={!controls.closeBtn}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">px</div>

                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Width</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              value={controls.closeBtn_width}
                              onChange={handleChangeNumbers}
                              name="closeBtn_width"
                              readOnly={!controls.closeBtn}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">px</div>

                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Left Margin</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              value={controls.closeBtn_leftMargin}
                              onChange={handleChangeNumbers}
                              name="closeBtn_leftMargin"
                              readOnly={!controls.closeBtn}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">px</div>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Right Margin</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              value={controls.closeBtn_rightMargin}
                              onChange={handleChangeNumbers}
                              name="closeBtn_rightMargin"
                              readOnly={!controls.closeBtn}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">px</div>

                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4 mb-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Top Margin</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              value={controls.closeBtn_topMargin}
                              onChange={handleChangeNumbers}
                              name="closeBtn_topMargin"
                              readOnly={!controls.closeBtn}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">px</div>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Bottom Margin</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              value={controls.closeBtn_bottomMargin}
                              onChange={handleChangeNumbers}
                              name="closeBtn_bottomMargin"
                              readOnly={!controls.closeBtn}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">px</div>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4 mb-4">
                      {/* <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Close Button Text</Label>
                <Input
                  type="text"
                  value={controls.closeBtn_Text}
                  onChange={handleChange}
                  name="closeBtn_Text"
                  readOnly={!controls.closeBtn}
                />
              </div>
            </div>
            <div className="col-md-1"></div> */}
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Close Button Info URL</Label>
                          <Input
                            type="text"
                            value={controls.closeBtn_InfoURL}
                            onChange={handleChange}
                            name="closeBtn_InfoURL"
                            readOnly={!controls.closeBtn}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="mb-3">
                      <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="logo"
                          checked={controls.logo} onChange={handleCheck} name="logo"
                        />
                        <label className="form-check-label " htmlFor="logo">
                          <h6 className="heading6 mt-1">Brand Identity</h6>
                        </label>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4">
                      {/* <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Position</Label>
                <Input
                  type="select"
                  className="form-select"
                  value={controls.logoPosition}
                  onChange={handleChange}
                  name="logoPosition"
                  disabled={!controls.logo}
                >
                  <option value="top-left">Top Left</option>
                  <option value="top-right">Top Right</option>
                </Input>
              </div>
            </div> */}
                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Height</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              value={controls.logoHeight}
                              onChange={handleChangeNumbers}
                              name="logoHeight"
                              readOnly={!controls.logo}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">px</div>

                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Width</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              value={controls.logoWidth}
                              onChange={handleChangeNumbers}
                              name="logoWidth"
                              readOnly={!controls.logo}
                              onKeyPress={allowOnlyNumbers}
                            />
                            <div className="input-group-text">px</div>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Link</Label>
                          <Input
                            type="text"
                            value={controls.logoURL}
                            onChange={handleChange}
                            name="logoURL"
                            readOnly={!controls.logo}
                          />
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Click through URL</Label>
                          <Input
                            type="text"
                            name="logoClickThroughURL"
                            value={controls.logoClickThroughURL}
                            onChange={handleChange}
                            readOnly={!controls.logo}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Text</Label>
                          <Input
                            type="text"
                            name="logoText"
                            value={controls.logoText}
                            onChange={handleChange}
                            readOnly={!controls.logo}
                          />
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      {/* <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Logo Lpu</Label>
                <Input
                  type="text"
                  name="logoLpu"
                  value={controls.logoLpu}
                  onChange={handleChange}
                  readOnly={!controls.logo}
                />
              </div>
            </div> */}
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Logo Text FontSize</Label>
                          <Input
                            type="text"
                            name="logoTextFontSize"
                            value={controls.logoTextFontSize}
                            onChange={handleChange}
                            readOnly={!controls.logo}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="row mt-2 ml-4">
                      <div className="col-md-5">
                        <div className="form-group">
                          <Label className="form-label">Logo Tool Tip</Label>
                          <Input
                            type="text"
                            name="logoToolTip"
                            value={controls.logoToolTip}
                            onChange={handleChange}
                            readOnly={!controls.logo}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Logo Text FontSize</Label>
                <Input
                  type="text"
                  name="logoTextFontSize"
                  value={controls.logoTextFontSize}
                  onChange={handleChange}
                  readOnly={!controls.logo}
                />
              </div>
            </div> */}
                    </div>


                  </TabPane>
                </TabContent>

              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        size="md"
        isOpen={showAlert}
        toggle={() => setShowAlert(!showAlert)}
        backdrop="static"
        centered
        id="staticBackdrop"
        className="glossary-modal"
      >
        <ModalBody className="px-3 py-4 text-center">
          {/* Icon */}
          <i className="bx bx-error-circle text-warning modal-icon" aria-hidden="true"></i>

          {/* Glossography with tooltips */}
          <h5 className="text-muted font-size-14 mb-4">
            If you enable <span className="highlight">Autoplay</span>, <span className="highlight">Click To Play</span> and <span className="highlight">User Consent</span> will be disabled.
          </h5>

          <h5 className="font-size-16 mb-4">
            Are you sure you still want to enable <span className="highlight">Autoplay</span> ?
          </h5>

          {/* Buttons */}
          <div className="d-flex justify-content-center gap-5 mb-0">
            <button type="button" className="btn btn-light" onClick={() => setShowAlert(!showAlert)}>No</button>
            <button type="button" className="btn btn-primary" onClick={autoPlayCheck}>Yes</button>
          </div>
        </ModalBody>
      </Modal>
    </div >
  )
}

export default PlayerControls
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Input,
  Spinner,
  Label,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  BreadcrumbItem
} from "reactstrap"

import DateRangePickers from '../../helpers/DateRangePicker'
import { Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import axios from "axios";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from "moment";
import LoadingTable from "pages/LoaderPages/LoadingTable";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days')

import {
  getUsers as onGetUsers,
} from "store/PartnerManagement/actions";

import {
  getRoles as onGetRoles,
} from "store/RoleManagement/actions";

import {
  getLogsMaster as onGetLogsMaster,
} from "store/Logs/actions";
import AdminSidebar from "./Admin_Sidebar/AdminSidebar";

var filterCount = 0

const Master_Log = props => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [masterLogData, setMasterLogData] = useState([])
  const [actionSearch, setActionSearch] = useState('')
  const [resourceTypeSearch, setResourceTypeSearch] = useState('')

  const [selectedPartner, setSelectedPartner] = useState(null);
  const [partnerData, setPartnerData] = useState([])
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleData, setRoleData] = useState([])

  const [switchLoader, setSwitchLoader] = useState(true)
  // const [loading, setLoading] = useState(false)

  const [state, setState] = useState({
    start: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
    end: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
  });

  const { start, end } = state;

  const handleEvent = (startDate, endDate, label) => {
    console.log(startDate, endDate)
    setState({ start: startDate, end: endDate, });
    fromDate = startDate._d.toISOString();
    toDate = endDate._d.toISOString();
    fetchMasterLogs(selectedPartner ? selectedPartner.label : '', selectedRole ? selectedRole.label : '', actionSearch, resourceTypeSearch, startDate._d.toISOString(), endDate._d.toISOString())
  };

  const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const [filterState, setFilterState] = useState(false)

  const actionChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (actionSearch === '') {
      filterCount = filterCount + 1
    }
    setActionSearch(e.target.value)
  }

  const resourceTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (resourceTypeSearch === '') {
      filterCount = filterCount + 1
    }
    setResourceTypeSearch(e.target.value)
  }

  function handleSelectPartner(selectPartner) {
    if (selectPartner.label === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (selectedPartner === null) {
      filterCount = filterCount + 1
    }
    setSelectedPartner(selectPartner);
    // fetchMasterLogs(selectPartner.label, selectedRole ? selectedRole.label : '', actionSearch, resourceTypeSearch, fromDate, toDate)
  }

  function handleSelectRole(selectRole) {
    if (selectRole.label === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (selectedRole  === null) {
      filterCount = filterCount + 1
    }
    setSelectedRole(selectRole);
    // fetchMasterLogs(selectedPartner ? selectedPartner.label : '', selectRole.label, actionSearch, resourceTypeSearch, fromDate, toDate)
  }

  // const actionChange = (e) => {
  //   setActionSearch(e.target.value)
  //   fetchMasterLogs(selectedPartner ? selectedPartner.label : '', selectedRole ? selectedRole.label : '', e.target.value, resourceTypeSearch, fromDate, toDate)
  // }

  // const resourceTypeChange = (e) => {
  //   setResourceTypeSearch(e.target.value)
  //   fetchMasterLogs(selectedPartner ? selectedPartner.label : '', selectedRole ? selectedRole.label : '', actionSearch, e.target.value, fromDate, toDate)
  // }

  const applyFilter = (e) => {
    fetchMasterLogs(selectedPartner ? selectedPartner.label : '', selectedRole ? selectedRole.label : '', actionSearch, resourceTypeSearch, fromDate, toDate)
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setActionSearch('')
    setResourceTypeSearch('')
    setSelectedPartner(null);
    setSelectedRole(null);
    filterCount = 0
    setFilterState(false)
    fetchMasterLogs('', '', '', '', fromDate, toDate)
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const { logsMaster } = useSelector(state => ({
    logsMaster: state.userLogs.logsMaster
  }));

  const { loading } = useSelector(state => ({
    loading: state.userLogs.loading,
  }));

  useEffect(() => {
    if (logsMaster !== null) {
      console.log(logsMaster)
      dispatch(onGetLogsMaster({
        "startDate": moment(start).format("YYYY-MM-DD"),
        "endDate": moment(end).format("YYYY-MM-DD"),
        "entity_name": resourceTypeSearch,
        "entity_value": "",
        "user": '',
        "role": '',
        "action": actionSearch
      }));
    }
  }, []);

  useEffect(() => {
    if (logsMaster !== null) {
      logsMaster.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("MM/DD/YYYY");
        setMasterLogData(logsMaster)
      })
    }
  }, [logsMaster]);

  // useEffect(() => {
  //   fetchMasterLogs('', '', actionSearch, resourceTypeSearch, start, end)
  // }, []);

  const fetchMasterLogs = (userName, roleName, action, typeSearch, fromDates, toDates) => {
    // setLoading(true)
    // const body = {
    //   "url": 'http://10.0.3.41:2007/master/report',
    //   "requestBody": {
    //     userID: userInfo.userID,
    //     userRole: userInfo.userRole,
    //     companyID: userInfo.companyID,
    //     "startDate": moment(fromDates).format("YYYY-MM-DD"),
    //     "endDate": moment(toDates).format("YYYY-MM-DD"),
    //     "entity_name": typeSearch ? typeSearch : '',
    //     "entity_value": "",
    //     "user": userName ? userName : '',
    //     "role": roleName ? roleName : '',
    //     "action": action ? action : ''
    //   }
    // }
    // axios.post("/connectReportAPI", body,
    //   {
    //     headers: headers
    //   })
    //   .then((res) => {
    //     console.log("result is ==>", res)
    //     // setLoading(false)
    //     if (res.data.responseData.success) {
    //       res.data.responseData.list.map((item, index) => {
    //         item.id = index + 1
    //       })
    //       setMasterLogData(res.data.responseData.list)
    //     }
    //   })
    //   .catch((err) => {
    //     // setLoading(false)
    //     console.log("e", err)
    //   });
    dispatch(onGetLogsMaster({
      "startDate": moment(fromDates).format("YYYY-MM-DD"),
      "endDate": moment(toDates).format("YYYY-MM-DD"),
      "entity_name": typeSearch ? typeSearch : '',
      "entity_value": "",
      "user": userName ? userName : '',
      "role": roleName ? roleName : '',
      "action": action ? action : ''
    }));
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const { roles } = useSelector(state => ({
    roles: state.roles.roles,
  }));

  const { partners } = useSelector(state => ({
    partners: state.partners.users,
  }));


  useEffect(() => {
    fromDate = moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days')
    toDate = moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days')
    if (partners !== null) {
      dispatch(onGetUsers({ filter: 'Active', role: '' }));
    }
  }, []);

  useEffect(() => {
    if (roles !== null) {
      dispatch(onGetRoles({ filter: 'Active' }));
    }
  }, []);

  useEffect(() => {
    if (partners && partners.length > 0) {
      setPartnerData(objectModal(partners))
    }
  }, [partners]);

  useEffect(() => {
    if (roles && roles.length > 0) {
      setRoleData(objectModalRoles(roles))
    }
  }, [roles]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.userID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const objectModalRoles = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.roleID
      obj.label = item.roleName
      dataList.push(obj)
    })
    return dataList
  }




  const userLogColoumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
    },
    {
      text: "Date",
      dataField: "date",
      sort: true,
      formatter: (cellContent, userLogs) => (
        <>
          <span>{moment(userLogs.date).format("DD/MM/YYYY")}</span>
        </>
      ),
    },
    {
      text: "User Name",
      dataField: "user",
      sort: true,
    },
    {
      text: "User Role",
      dataField: "role",
      sort: true,
    },
    {
      text: "Resource Type",
      dataField: "entity_name",
      sort: true,
    },
    {
      text: "Resource Value",
      dataField: "entity_value",
      sort: true,
    },
    {
      text: "Resource Action",
      dataField: "action",
      sort: true,
    },
  ];


  const keyField = "id";

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);



  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      {/* <AdminSidebar />
      <div className="main-content access-management"> */}
      <div className="page-content">
        <MetaTags>
          <title>Master Log | Bidsxchange</title>
        </MetaTags>
        <Container fluid={true}>
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Admin" breadcrumbItem="Master Log" />
            {/* <Spinner className="ms-2 loader" color="primary" /> */}

            <div className="text-sm-end">
              <div className="btn-group">
                <Dropdown
                  direction="left"
                  isOpen={isMenu}
                  toggle={toggleMenu}
                >
                  <DropdownToggle
                    tag="button"
                    className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                  >
                    <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                    {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                      Filter Applied
                    </span> : <span className="d-none font-16 d-sm-inline-block">
                      Filter
                    </span>
                    }
                    {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                    <div className="dropdown-item-text">
                      <div className="row">
                        <h5 className="mb-0">Filters</h5>
                      </div>
                    </div>

                    <DropdownItem divider />
                    <Card>
                      <CardBody aria-hidden="true">
                        <Row >
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">User</Label> <br />
                            <Select
                              value={selectedPartner}
                              onChange={s => {
                                handleSelectPartner(s)
                              }}
                              options={partnerData}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Roles</Label> <br />
                            <Select
                              value={selectedRole}
                              onChange={s => {
                                handleSelectRole(s)
                              }}
                              options={roleData}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Resource Type</Label> <br />
                            <div className=" text-sm-end">
                              <Input type="select" className="form-select" value={resourceTypeSearch} onChange={resourceTypeChange}>
                                <option value=''> All Resource Types</option>
                                <option value='Advertisers' >Advertisers</option>
                                <option value='IOGroups'> Insertion Order Groups</option>
                                <option value='IOs'> Insertion Orders</option>
                                <option value='LineItems' >Line Items</option>
                                <option value='Creatives'> Creatives</option>
                                <option value='Partners'> Partners</option>
                                <option value='Publishers'> Publishers</option>
                                <option value='AdUnitGroups'> Ad Unit Groups</option>
                                <option value='AdUnits' >Ad Units</option>
                                <option value='ChildAdUnits'> Child AdUnits</option>
                                <option value='HighImpactSettings'> HighImpact Settings </option>
                                <option value='RevenueReport'> Revenue Report</option>
                                <option value='Invoices'> Invoices  </option>
                              </Input>
                            </div>
                          </Col>
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Resource Action</Label> <br />
                            <div className=" text-sm-end">
                              <Input type="select" className="form-select" value={actionSearch} onChange={actionChange}>
                                <option value=''> All Resource Actions </option>
                                <option value='create'> Create </option>
                                <option value='update'> Update </option>
                                <option value='statusChange'> Status Change  </option>
                                <option value='archive'> Archive</option>
                                <option value='Report quried'> Report Queried</option>
                                <option value='approval'> Approval </option>
                              </Input>
                            </div>
                          </Col>

                        </Row>
                      </CardBody>
                    </Card>

                    <DropdownItem divider />
                    <Row>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={clearAllFilter}
                        >
                          Clear ALL
                          {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                        </DropdownItem>
                      </Col>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={applyFilter}
                        >
                          Apply Filter
                          {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                        </DropdownItem>
                      </Col>
                    </Row>

                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                          <ToolkitProvider
                            keyField={keyField}
                            data={masterLogData}
                            columns={userLogColoumns}
                            // bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="4"></Col>
                                  <Col sm="4" className="date-range ">
                                  <div className="text-sm-end">
                                    {/* <Label className="form-label">Date Range</Label> <br /> */}
                                    <DateRangePicker
                                      initialSettings={{
                                        opens: 'left',
                                        startDate: start.toDate(),
                                        endDate: end.toDate(),
                                        ranges: {
                                          // Today: [moment(), moment()],
                                          Yesterday: [
                                            moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                            moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                          ],
                                          'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                          'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                          'This Month': [moment().startOf('month'), moment().endOf('month')],
                                          'Last Month': [
                                            moment().subtract(1, 'month').startOf('month'),
                                            moment().subtract(1, 'month').endOf('month'),
                                          ],
                                        },
                                      }}
                                      onCallback={handleEvent}
                                    >
                                      <button className='datePicker' type="button">
                                        <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                      </button>
                                    </DateRangePicker>
                                  </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12" className="table-responsive">
                                    <div >
                                      <BootstrapTable
                                        keyField={keyField}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        pagination={paginationFactory(pageOptions)}
                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                      // ref={node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}
      {/* </div> */}
    </React.Fragment >
  )
}

Master_Log.propTypes = {}

export default Master_Log
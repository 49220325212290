/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Select from "react-select";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form
} from "reactstrap"

import jsPDF from "jspdf";
import "jspdf-autotable";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import moment from "moment";

import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

import {
    getProxyAdUnitsReports as onGetProxyAdUnitsReports,
} from "store/Proxy/ProxyAdUnits/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate =  moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate =  moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')
var loader = false

const QueryReport = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [requestTotal, setRequestTotal] = useState(0)
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('daily')
    // const [loader, setLoader] = useState(false)

    const [AdUnitName, setAdUnitName] = useState([]);
    const [AdUnitSize, setAdUnitSize] = useState([]);
    const [SourcePID, setSourcePID] = useState([]);
    const [SourcePName, setSourcePName] = useState([]);
    const [PassBackPName, setPassBackPName] = useState([]);
    const [PassBackCName, setPassBackCName] = useState([]);

 
    const [selectedAdUnitName, setSelectedAdUnitName] = useState(null);
    const [selectedAdUnitSize, setSelectedAdUnitSize] = useState(null);
    const [selectedSourcePID, setSelectedSourcePID] = useState(null);
    const [selectedSourcePName, setSelectedSourcePName] = useState(null);
    const [selectedPassBackPName, setSelectedPassBackPName] = useState(null);
    const [selectedPassBackCName, setSelectedPassBackCName] = useState(null);

    const [selectAllDM, setSelectAllDM] = useState({
        name: false,
        size: false,
        sourceplatformid: false,
        sourceplatformname: false,
        passbackplatformname: false,
        passbackcreativename: false
    });

    const [filters, setFilters] = useState({
        name: '',
        size: '',
        sourceplatformid: '',
        sourceplatformname: '',
        passbackplatformname: '',
        passbackcreativename: ''
    });

    const [filterData, setFilterData] = useState({
        name: [],
        size: [],
        sourceplatformid: [],
        sourceplatformname: [],
        passbackplatformname: [],
        passbackcreativename: []
    })

      const handleSelectAdUnitName = adUnitName => {
        setSelectedAdUnitName(adUnitName)
        setFilters(filters => ({ ...filters, name: adUnitName.value }));
        changeData('AdUnitName',adUnitName.value)
      }
      
      const handleSelectAdUnitSize = adUnitSize => {
        setSelectedAdUnitSize(adUnitSize)
        setFilters(filters => ({ ...filters, size: adUnitSize.value }));
        changeData('AdUnitSize',adUnitSize.value)
      }
    
      const handleSelectSourcePID = sourceplatformid => {
        setSelectedSourcePID(sourceplatformid)
        setFilters(filters => ({ ...filters, sourceplatformid: sourceplatformid.value }));
        changeData('SourcePID',sourceplatformid.value)
      }
    
      const handleSelectSourcePName = sourceplatformname => {
        setSelectedSourcePName(sourceplatformname)
        setFilters(filters => ({ ...filters, sourceplatformname: sourceplatformname.value }));
        changeData('SourcePName',sourceplatformname.value)
      }

      const handleSelectPassBackPName = passbackplatformname => {
        setSelectedPassBackPName(passbackplatformname)
        setFilters(filters => ({ ...filters, passbackplatformname: passbackplatformname.value }));
        changeData('PassBackPName',passbackplatformname.value)
      }

      const handleSelectPassBackCName = passbackcreativename => {
        setSelectedPassBackCName(passbackcreativename)
        setFilters(filters => ({ ...filters, passbackcreativename: passbackcreativename.value }));
        changeData('PassBackCName',passbackcreativename.value)
      }

    const changeFilter = (e) => {
        const { name, value } = e.target;
        setFilters(filters => ({ ...filters, [name]: value }));
        changeData(e)
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
        }
    }

    useEffect(() => {
        fromDate =  moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
        toDate =  moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

        body.url = "http://127.0.0.1:4012/v1.0/Ssrv/filter"
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData) {
                    objectModalData(res.data.responseData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }, []);

    const changeData = (name,value) => {
        resetSelectedData(name)
        body.url = "http://127.0.0.1:4012/v1.0/Ssrv/specificFilter"
        body.requestBody = {
            ...body.requestBody,
            "name": name === 'AdUnitName' ? value : '',
            "size": name === 'AdUnitSize' ? value : '',
            "sourceplatformid": name === 'SourcePID' ? value : '',
            "sourceplatformname": name === 'SourcePName' ? value : '',
            "passbackplatformname": name === 'PassBackPName' ? value : '',
            "passbackcreativename": name === 'PassBackCName' ? value : '',
        }
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData) {
                    objectModalData(res.data.responseData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const resetSelectedData = (name) => {
        if(name === 'AdUnitName'){
            setSelectedAdUnitSize(null)
            setSelectedSourcePID(null)
            setSelectedSourcePName(null)
            setSelectedPassBackPName(null)
            setSelectedPassBackCName(null)
        }
        else if(name === 'AdUnitSize'){
            setSelectedSourcePID(null)
            setSelectedSourcePName(null)
            setSelectedPassBackPName(null)
            setSelectedPassBackCName(null)
        }
        else if(name === 'SourcePID'){
            setSelectedSourcePName(null)
            setSelectedPassBackPName(null)
            setSelectedPassBackCName(null)
        }
        else if(name === 'SourcePName'){
            setSelectedPassBackPName(null)
            setSelectedPassBackCName(null)
        } else if(name === 'PassBackPName'){
            setSelectedPassBackCName(null)
        }
        else {
        }
}

    const objectModalData = (response) => {
        if(response.name){
            setAdUnitName(objectModal(response.name))
        }
        if(response.size){
            setAdUnitSize(objectModal(response.size))
        }
        if(response.sourceplatformid){
            setSourcePID(objectModal(response.sourceplatformid))
        }
        if(response.sourceplatformname){
            setSourcePName(objectModal(response.sourceplatformname))
        }
        if(response.passbackplatformname){
            setPassBackPName(objectModal(response.passbackplatformname))
        }
        if(response.passbackcreativename){
            setPassBackCName(objectModal(response.passbackcreativename))
        }
    
      }

      const objectModal = (response) => {
        console.log
        var dataList = []
        response.map((item, index) => {
          let obj = {}
          obj.value = item
          obj.label = item
          dataList.push(obj)
        })
        return dataList
      }

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            name: e.target.checked,
            size: e.target.checked,
            sourceplatformid: e.target.checked,
            sourceplatformname: e.target.checked,
            passbackplatformname: e.target.checked,
            passbackcreativename: e.target.checked
        })
    }

    const changeSelection = (e) => {
        setSelectAll(false)
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.proxyAdUnit.errorMsg,
        successMsg: state.proxyAdUnit.successMsg,
    }));

    console.log(errorMsg, successMsg)

    const { proxyAdUnitsReports } = useSelector(state => ({
        proxyAdUnitsReports: state.proxyAdUnit.proxyAdURpt,
    }));

    const { loading } = useSelector(state => ({
        loading: state.proxyAdUnit.loading,
      }));

    console.log(proxyAdUnitsReports)

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const proxyAdUnitsReportsColumns = [
        {
            text: "No",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
            footer: ''
        },
    ];

    const createColoumn = () => {

    }

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        let DimArr = Object.values(selectAllDM)
        if (!selectMetrice) {
            toastr.warning('Please select atleast one Metrices')
        }
        else {
                      // setLoader(true)
                      loader = true

            let query = {
                "dimensions": selectAllDM,
                "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD"),
                "filters": filters,
                "metrices": {
                    "requests": selectMetrice
                },
                "range": range,
                "type": 'proxy'
            }
            console.log(query)
            dispatch(onGetProxyAdUnitsReports({ ...query }));
            setReportOpen(true)
        }
    }

    if (proxyAdUnitsReports.length > 0) {
        let keys = [];
        for (var k in proxyAdUnitsReports[0]) keys.push(k);
        if(keys.includes("hour")){
            proxyAdUnitsReports.map((item, index) => {
                item.hour = parseInt(item.hour)
            })
        }
        console.log(keys)
        if (keys.includes("date")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "date",
                text: "Date",
                sort: true,
                footer: 'Total Requests'
            })
        }
        if (keys.includes("month")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "month",
                text: "Month",
                sort: true,
                footer: 'Total Requests'
            })
        }
        if (keys.includes("hour")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "hour",
                text: "Hour",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("ad_unit_name")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "ad_unit_name",
                text: "Proxy Ad Unit",
                sort: true,
                footer: '',
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("ad_unit_size")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "ad_unit_size",
                text: "Size",
                sort: true,
                footer: ''
            })
        }

        if (keys.includes("source_platform_id")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "source_platform_id",
                text: "Source Platform ID",
                sort: true,
                footer: '',
            })
        }
        if (keys.includes("source_platform_name")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "source_platform_name",
                text: "Source Platform ",
                sort: true,
                footer: '',
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("passback_platform_name")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "passback_platform_name",
                text: "Passback Platform",
                sort: true,
                footer: '',
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("passback_creative_name")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "passback_creative_name",
                text: "Passback Creative",
                sort: true,
                footer: '',
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("requests")) {
            proxyAdUnitsReportsColumns.push({
                dataField: "requests",
                text: "Requests",
                sort: true,
                footer: `${requestTotal}`
            })
        }

        // loader = false
        loader = false
        console.log(proxyAdUnitsReportsColumns)
    } else {
        loader = false
    }

    useEffect(() => {
        console.log(proxyAdUnitsReports)
        if (proxyAdUnitsReports !== null && proxyAdUnitsReports.length > 0) {
            // setLoader(false)
            loader = false
            let totalRequest = 0
            proxyAdUnitsReports.map((item, index) => {
                totalRequest = totalRequest + item.requests
            })
            setRequestTotal(totalRequest)
        } else {
            // setLoader(false)
            loader = false
        }

    }, [proxyAdUnitsReports]);

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    const copyTable = () => {
        console.log(CSVProp, JSON.stringify(CSVProp))
        navigator.clipboard.writeText(CSVProp)
        // const elTable = document.querySelector('table');

        // let range, sel;

        // if (document.createRange && window.getSelection) {

        //   range = document.createRange();
        //   sel = window.getSelection();
        //   sel.removeAllRanges();

        //   try {
        //     range.selectNodeContents(elTable);
        //     sel.addRange(range);
        //   } catch (e) {
        //     range.selectNode(elTable);
        //     sel.addRange(range);
        //   }

        //   document.execCommand('copy');
        // }

        // sel.removeAllRanges();

        console.log('Element Copied! Paste it in a file')

    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Proxy AdUnit Report";
        const headers = [];

        // const data = this.state.people.map(elt=> [elt.name, elt.profession]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    const [state, setState] = useState({
        start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
        end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
      });
      
      const { start, end } = state;
      
      const handleEvent = (startDate, endDate,label) => {
        console.log(startDate,endDate)
        setState({ start: startDate, end: endDate,});
        fromDate = startDate._d.toISOString();
        toDate = endDate._d.toISOString();
      };
      
       const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <MetaTags>
                        <title>Proxy Report | Bidsxchange</title>
                    </MetaTags>
                    {!reportOpen &&
                        <Container fluid>
                            {/* Render Breadcrumbs */}
                            <div className=" d-sm-flex  justify-content-between">
                                <SupplyBreadCrumb title="Proxy" breadcrumbItem="Proxy Report Query " />
                            </div>
                            <Row>
                                <div className="col-12">
                                    <Card>
                                        <CardBody>
                                            <Form onSubmit={handleSubmit}>
                                                <Row style={{ padding: '1% 3%', }}>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <h5>SELECT DATE</h5> <br />
                                                            <DateRangePicker
                                                                initialSettings={{
                                                                opens: 'left',
                                                                startDate: start.toDate(),
                                                                endDate: end.toDate(),
                                                                maxDate: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                ranges: {
                                                                    Today: [ moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                    moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),],
                                                                    Yesterday: [
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                        ],
                                                                        'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                        'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                    'Last Month': [
                                                                    moment().subtract(1, 'month').startOf('month'),
                                                                    moment().subtract(1, 'month').endOf('month'),
                                                                    ],
                                                                },
                                                                }}
                                                                onCallback={handleEvent}
                                                                >
                                                                <button className='datePicker' type="button">
                                                                <i  className="bx bx-calendar font-size-18 " id="edittooltip" style={{margin:'3% 3% 2%'}} ></i>{labelDate}
                                                                </button>
                                                                </DateRangePicker>
                                                        </div>
                                                        <br />
                                                        <h5>FILTERS</h5>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Proxy Ad Unit Name</Label>
                                                            <Select
                                                                value={selectedAdUnitName}
                                                                onChange={s => {
                                                                    handleSelectAdUnitName(s)
                                                                }}
                                                                options={AdUnitName}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label> Ad Unit Size</Label>
                                                            <Select
                                                                value={selectedAdUnitSize}
                                                                onChange={s => {
                                                                    handleSelectAdUnitSize(s)
                                                                }}
                                                                options={AdUnitSize}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Source Platform ID</Label>
                                                            <Select
                                                                value={selectedSourcePID}
                                                                onChange={s => {
                                                                    handleSelectSourcePID(s)
                                                                }}
                                                                options={SourcePID}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Source Platform Name</Label>
                                                            <Select
                                                                value={selectedSourcePName}
                                                                onChange={s => {
                                                                    handleSelectSourcePName(s)
                                                                }}
                                                                options={SourcePName}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Passback Platform Name</Label>
                                                            <Select
                                                                value={selectedPassBackPName}
                                                                onChange={s => {
                                                                    handleSelectPassBackPName(s)
                                                                }}
                                                                options={PassBackPName}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Passback Creative Name</Label>
                                                            <Select
                                                                value={selectedPassBackCName}
                                                                onChange={s => {
                                                                    handleSelectPassBackCName(s)
                                                                }}
                                                                options={PassBackCName}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0% 3%', }}>

                                                        <div className="mb-3">
                                                            <h5> SELECT RANGE</h5><br />
                                                            <Input
                                                                name="range"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={changeRange}
                                                                value={range}
                                                            >
                                                                <option value='' disabled>Select</option>
                                                                <option value='alltime'>All Time</option>
                                                                <option value='monthly'>Monthly</option>
                                                                <option value='daily'>Daily</option>
                                                                <option value='hourly'>Hourly</option>
                                                            </Input>
                                                        </div>
                                                        <br />
                                                        <h5 className="mb-3 ">DIMENSIONS</h5>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAll}
                                                                onChange={changeDMSelect}
                                                                id="selectAll"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="selectAll"
                                                            >
                                                                Select All
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.name}
                                                                name="name"
                                                                onChange={changeSelection}
                                                                id="proxyadunit"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="proxyadunit"
                                                            >
                                                                Proxy Ad Unit
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.size}
                                                                name="size"
                                                                onChange={changeSelection}
                                                                id="adsize"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="adsize"
                                                            >
                                                                Ad Size
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.sourceplatformid}
                                                                name="sourceplatformid"
                                                                onChange={changeSelection}
                                                                id="sourceplatformid"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="sourceplatformid"
                                                            >
                                                                Source Platform ID
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.sourceplatformname}
                                                                name="sourceplatformname"
                                                                onChange={changeSelection}
                                                                id="sourceplatformname"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="sourceplatformname"
                                                            >
                                                                Source Platform Name
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.passbackplatformname}
                                                                name="passbackplatformname"
                                                                onChange={changeSelection}
                                                                id="passbookplatformname"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="passbookplatformname"
                                                            >
                                                                Passback Platform Name
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.passbackcreativename}
                                                                name="passbackcreativename"
                                                                onChange={changeSelection}
                                                                id="passbookcreativename"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="passbookcreativename"
                                                            >
                                                                Passback Creative Name
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> SELECT Time Zone</h5><br />
                                                            <Input
                                                                name="timeZone"
                                                                type="select"
                                                                className="form-select"
                                                                defaultValue={'EST'}
                                                            >
                                                                <option value='' disabled>Select</option>
                                                                <option value='EST'>EST</option>
                                                            </Input>
                                                        </div>
                                                        <br />
                                                        <h5 className="mb-3">METRICES</h5>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectMetrice}
                                                                onChange={(e) => setSelectMetrice(!selectMetrice)}
                                                                id="request"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="request"
                                                            >
                                                                Request
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className=" text-sm-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary save-user"
                                                            >
                                                                Run Report
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>
                        </Container>
                    }
                    {reportOpen &&
                        <Container fluid={true}>
                            <div className=" d-sm-flex  justify-content-between">
                                <SupplyBreadCrumb title="Proxy" breadcrumbItem="Proxy Report " />
                                <div className=" text-sm-end">
                                    <div className="btn-group">
                                        <Dropdown
                                            isOpen={btnprimary1}
                                            toggle={() => setBtnprimary1(!btnprimary1)}
                                        >
                                            <DropdownToggle tag="button" className="btn btn-primary">
                                                Export
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={copyTable}>Copy</DropdownItem>
                                                <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                                <DropdownItem onClick={exportPDF}>PDF</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={(e) => setReportOpen(false)}
                                    >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Modify Report
                                    </Button>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody aria-hidden="true">
                                            {loading &&
                                                <>
                                                    <Spinner className="ms-2 loader" color="primary" />
                                                    <br />
                                                    <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                    <br />
                                                </>
                                            }
                                            {proxyAdUnitsReports.length === 0 && !loading &&
                                                <h5 className="text-center" style={{ padding: '15%', fontSize: '25px' }}>No Data</h5>
                                            }
                                            {proxyAdUnitsReports.length > 0 && !loading &&
                                                            <ToolkitProvider
                                                                keyField={keyField}
                                                                data={proxyAdUnitsReports}
                                                                columns={proxyAdUnitsReportsColumns}
                                                                // bootstrap4
                                                                search
                                                                exportCSV={{
                                                                    ignoreFooter: false
                                                                }}
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col sm="8">
                                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                                    <div className="position-relative">
                                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm="1">
                                                                                <MyExportCSV {...toolkitProps.csvProps} />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl="12" className="table-responsive">
                                                                                <div >
                                                                                    <BootstrapTable
                                                                                        keyField={keyField}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={defaultSorted}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        pagination={paginationFactory(pageOptions)}
                                                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    }

                </div>
            {/* </div> */}
        </React.Fragment>
    )
}

QueryReport.propTypes = {}

export default QueryReport